import {Component, EventEmitter, HostListener, inject, OnInit, Output} from '@angular/core';
import {VALIDATION_MESSAGES} from "../../../../shared/consts/validations.const";
import {DashboardService} from "../../../../dashboard.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CreateAccountService} from "../../create-account.service";
import {CompleteAccount, PersonalDataInterface, Phone} from "../../models/personalDataInterface";
import {DialogService} from "../../../../shared/components/custom-dialog/dialog.service";
import {LocalStorageService} from "../../../../core/storage/local-storage.service";
import {User} from "../../../../shared/models/user-data.model";
import {environment} from "../../../../../environments/environment";

@Component({
    selector: 'app-form-complete-account',
    standalone: true,
    imports: [ReactiveFormsModule],
    templateUrl: './form-complete-account.component.html',
    styleUrl: './form-complete-account.component.scss'
})
export class FormCompleteAccountComponent implements OnInit {
    private dialogService = inject(DialogService);
    private localStorageService = inject(LocalStorageService);
    @Output("accountInfo") accountInfo: EventEmitter<any> = new EventEmitter()
    public validationMessages = VALIDATION_MESSAGES;
    isLoading: boolean = false;
    public showPassword: boolean = false;
    public showConfirmPassword: boolean = false;


    private dashboardService = inject(DashboardService)
    private createAccountService = inject(CreateAccountService)

    public completeDataForm: FormGroup<any> = this.dashboardService.formAlta.get('completeData') as FormGroup;
    userData: any;
    provincias: any[] = [];


    ngOnInit(): void {
        this.provincias = [

            {"id": 1, "nombre": "Buenos Aires"},
            {"id": 2, "nombre": "CABA"},
            {"id": 3, "nombre": "Catamarca"},
            {"id": 4, "nombre": "Chaco"},
            {"id": 5, "nombre": "Chubut"},
            {"id": 6, "nombre": "Córdoba"},
            {"id": 7, "nombre": "Corrientes"},
            {"id": 8, "nombre": "Entre Ríos"},
            {"id": 9, "nombre": "Formosa"},
            {"id": 10, "nombre": "Jujuy"},
            {"id": 11, "nombre": "La Pampa"},
            {"id": 12, "nombre": "La Rioja"},
            {"id": 13, "nombre": "Mendoza"},
            {"id": 14, "nombre": "Misiones"},
            {"id": 15, "nombre": "Neuquén"},
            {"id": 16, "nombre": "Río Negro"},
            {"id": 17, "nombre": "Salta"},
            {"id": 18, "nombre": "San Juan"},
            {"id": 19, "nombre": "San Luis"},
            {"id": 20, "nombre": "Santa Cruz"},
            {"id": 21, "nombre": "Santa Fe"},
            {"id": 22, "nombre": "Santiago del Estero"},
            {"id": 23, "nombre": "Tierra del Fuego"},
            {"id": 24, "nombre": "Tucumán"}

        ];

        let dataUser = this.localStorageService.getItem("user");
        if (dataUser !== null) {
            this.userData = dataUser;
        }

        this.loadFormData()
        // this.validadForm()
    }

    loadFormData() {
        const formData = this.dashboardService.getFormValues('completeData');
        this.completeDataForm.patchValue(formData);
    }

    // validadForm() {
    //     this.completeDataForm = new FormGroup({
    //         name: new FormControl(this.userData?.name ? this.userData.name : '', Validators.required),
    //         lastname: new FormControl(this.userData?.lastname ? this.userData.lastname : '', Validators.required),
    //         document_number: new FormControl(this.userData?.document_number ? this.userData.document_number : '', Validators.required),
    //         document_type: new FormControl(this.userData?.document_type ? this.userData.document_type : '', Validators.required),
    //         gender: new FormControl(this.userData?.gender ? this.userData.gender : '', Validators.required),
    //         email: new FormControl(this.userData?.email ? this.userData.email : '', Validators.required),
    //         telephone_number: new FormControl('', [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]{0,10}$')]),
    //         address: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9\\s]*$')]),
    //         province: new FormControl('', Validators.required),
    //         zip: new FormControl('', [Validators.required, Validators.pattern('^\\d{0,4}$')]),
    //         password: new FormControl('', [
    //             Validators.required,
    //             Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?])[A-Za-z\\d!@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?]{8,16}$')
    //         ]),
    //         confirm_password: new FormControl('', Validators.required),
    //     })
    // }

    public getValidationPersonalFromControl(validation: any, elem: string): any {
        //@ts-ignore
        return this.completeDataForm.get(elem).invalid && this.completeDataForm.get(elem).hasError(validation.type) && (this.completeDataForm.get(elem).dirty || this.completeDataForm.get(elem).touched)
    }

    getConfirmPass() {
        const pass1 = this.completeDataForm.get('password')?.value;
        const pass2 = this.completeDataForm.get('confirm_password')?.value;
        return (pass1 === pass2) ? false : true;
    }

    emitForm() {
        this.isLoading = true;
        console.log(this.completeDataForm);
        if (this.completeDataForm.invalid) {
            this.completeDataForm.markAllAsTouched();
            this.isLoading = false;
            this.dialogService.customMessage('Error en el formulario', 'Por favor complete todos los campos');
            return;
        }

        const completeAccount = this.loadDataForm();

        this.createAccountService.$completeAccount.next(completeAccount)
    }

    loadDataForm() {
        const password = this.completeDataForm.get('password')?.value;

        const phone: Phone = {
            area_code: '',
            number: this.completeDataForm.get('telephone_number')?.value,
            is_mobile: true,
            is_principal: true
        };

        // comentario para que tome el cambio de lastname
        const data: CompleteAccount = {
            // uid: this.userData.uid,
            email: this.completeDataForm.get('email')?.value,
            document_type: this.completeDataForm.get('document_type')?.value,
            document_number: this.completeDataForm.get('document_number')?.value,
            name: this.completeDataForm.get('name')?.value,
            last_name: this.completeDataForm.get('last_name')?.value,
            gender: this.completeDataForm.get('gender')?.value,
            phones: [phone],
            address: {
                street: this.completeDataForm.get('address')?.value,
                street_number: '',
                department: '',
                floor: '',
                zip_code: this.completeDataForm.get('zip')?.value,
                province_description: '',
                province_id: this.completeDataForm.get('province')?.value,
                locality_description: '',
                locality_id: ''
            },
            accept_terms: true
        };

        return {
            completeAccount: data,
            password: password
        };
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.ctrlKey && event.key === '4') {
            this.fillFormWithExampleData();
        }
    }

    fillFormWithExampleData() {
        if (!environment.production) {
            const phone: Phone = {
                area_code: '',
                number: '1167676787',
                is_mobile: true,
                is_principal: true
            };

            this.completeDataForm.setValue({
                last_name: 'Palet',
                name: 'Maxi',
                document_type: 'DNI',
                document_number: '12345678',
                gender: 'M',
                email: 'm.l.palet@gmail.com',
                password: 'Admin_123',
                telephone_number: '1167676787',
                address: {
                    street: 'Paris 345',
                    street_number: '',
                    department: '',
                    floor: '',
                    zip_code: '7000',
                    province_description: 'Buenos Aires',
                    province_id: '1',
                    locality_description: '',
                    locality_id: ''
                },
                accept_terms: true
            });
        }
    }
}
