import {Component, inject} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import {NavbarComponent} from "./shared/components/navbar/navbar.component";
import {CreateAccountComponent} from "./features/03-create-account/create-account.component";
import { initFlowbite } from 'flowbite';
import {LoginComponent} from "./features/01-login/login.component";
import {FooterComponent} from "./shared/components/footer/footer.component";
import {DialogService} from "./shared/components/custom-dialog/dialog.service";
import {AuthService} from "./core/01-auth/services/auth.service";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, LoginComponent, NavbarComponent, CreateAccountComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  authService = inject(AuthService);
  dialogService = inject(DialogService);
  title = 'autogestion-webapp';
  ngOnInit(): void {
    initFlowbite();
    this.authService.isAuthenticated();
    this.showLegal();
  }

  showLegal(){
    const message = '<span class="text-sm">A partir de Diciembre 2023, nace Life Insurtech Compañía de Seguros S.A., una compañía de seguros del Grupo ST, surgida a partir de la adquisición de Cardif Seguros S.A.</span>\n' +
        "<br><br> Cardif Seguros S.A (cambio de nombre a Life Insurtech Compañía de Seguros S.A. pendiente de inscripción ante la Inspección General de Justicia)\n";
    this.dialogService.customMessage("", message)
  }
}
