import {Component, inject, OnInit} from '@angular/core';
import {LoginFormComponent} from "../01-login/components/01-login-form/login-form.component";
import {PolicyService} from "./policy.service";
import {DialogService} from "../../shared/components/custom-dialog/dialog.service";
import {PolicyCardComponent} from "./components/policy-card/policy-card.component";
import {InsurancePolicy} from "./models/homeInterface";
import {Router} from "@angular/router";
import {FooterComponent} from "../../shared/components/footer/footer.component";

let CardCoverageComponent;

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [
    LoginFormComponent,
    PolicyCardComponent,
    FooterComponent
  ],
  templateUrl: './policy.component.html',
  styleUrl: './policy.component.scss'
})
export class PolicyComponent implements OnInit {
  private policyService = inject(PolicyService);
  private dialogService = inject(DialogService);
  private router = inject(Router);
  protected isLoading: boolean = false;

  certificates: InsurancePolicy[] | undefined = [];

  ngOnInit(): void {
    this.isLoading = true;
    this.policyService.loadInsurances().subscribe({
      next: (res: InsurancePolicy[]) => {
        this.certificates = this.policyService.uploadImages(res);
        this.isLoading = false;
      },
      error: (error: any) => {
        this.isLoading = false;
        if (error.message === 'ERROR_RESOLVED') {
          return;
        }
      }
    });
  }

  getPolySelected($event: InsurancePolicy) {
    this.policyService.setSelectedPolicy($event);
    this.router.navigate(['detalle-poliza']);
  }

  downloadPolicy($event: InsurancePolicy) {
    const policyNumber = !$event.official_certificate_number || $event.official_certificate_number === '0' ?
        $event.official_policy_number : $event.official_certificate_number;
    this.policyService.downloadPolicy($event).subscribe(response => {
      if (response) {
        const downloadLink = document.createElement('a');
        downloadLink.style.display = 'none';
        document.body.appendChild(downloadLink);

        const blobUrl = window.URL.createObjectURL(response);
        downloadLink.setAttribute('href', blobUrl);
        downloadLink.setAttribute(
            'download',
            `poliza-${policyNumber.toString()}`
        );

        downloadLink.click();

        document.body.removeChild(downloadLink);
        // Revocar la URL del blob para liberar memoria
        window.URL.revokeObjectURL(blobUrl);
      } else {
        console.error('Error: No se pudo descargar el archivo.');
      }
    });
  }
}