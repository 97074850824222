import {Component, EventEmitter, inject, input, Input, Output, output} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-stepper-header',
  standalone: true,
  imports: [],
  templateUrl: './stepper-header.component.html',
  styleUrl: './stepper-header.component.scss'
})
export class StepperHeaderComponent {
  title = input.required<string>();
  length = input.required< number>();
  position = input.required< number>();
  @Output() changePosition = new EventEmitter<number>();
  private router = inject(Router);
  private location: Location = inject(Location);

  backStep() {
    if (this.position() > 1) {
        this.changePosition.emit(this.position() - 1);
        return;
    }
    this.location.back();
  }
}
