import {Component, EventEmitter, Input, input, OnInit, Output} from '@angular/core';
import {StepperMapComponent} from "./components/stepper-map/stepper-map.component";
import {StepperHeaderComponent} from "./components/stepper-header/stepper-header.component";
import {StepperBodyComponent} from "./components/stepper-body/stepper-body.component";
import {
    FormCreateAccountComponent
} from "../../../features/03-create-account/components/form-create-account/form-create-account.component";
import {MapStepperInterface, StepperStatus} from "./models/mapStepper.interface";
import {BehaviorSubject} from "rxjs";
import {
    ChangePasswordComponent
} from "../../../features/04-forgot-password/components/change-password/change-password.component";

@Component({
    selector: 'app-stepper',
    standalone: true,
    imports: [
        StepperMapComponent,
        StepperHeaderComponent,
        StepperBodyComponent
    ],
    templateUrl: './stepper.component.html',
    styleUrl: './stepper.component.scss'
})
export class StepperComponent implements OnInit {
    headerTitle = input.required<string>();
    mapTitle = input.required<string>();
    $mapSteps = input.required<BehaviorSubject<MapStepperInterface[]>>();
    headerLength = input.required<number>();
    headerPositionActive = input.required<number>();
    @Output() changePosition = new EventEmitter<number>();
    activeComponent: any;
    @Input() activeComponentPosition!: BehaviorSubject<any>;
    position: number = 0;
    activeComponentChange: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    mapSteps: MapStepperInterface[] = [];
    ngOnInit() {
        this.$mapSteps().subscribe((mapSteps: MapStepperInterface[]) => {
            this.mapSteps = mapSteps;
            this.mapSteps.forEach((step: any) => {
                        if (step.status === StepperStatus.ACTIVE) {
                            this.activeComponent = step.component;
                            this.activeComponentChange.next(this.activeComponent);
                        }
                    }
                )
            }
        )

    }

    headerPositionChange($event: any) {
        this.mapSteps.forEach((step: any) => {
                if (step.position === $event) {
                    this.activeComponent = step.component;
                    step.status = StepperStatus.ACTIVE;
                    this.activeComponentChange.next(this.activeComponent);
                    this.changePosition.emit($event);
                    return;
                }
                if (step.position > $event) {
                    step.status = StepperStatus.PENDING;
                }
            })
    }
}
