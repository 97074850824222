import {Component, effect, HostListener, inject, OnInit} from '@angular/core';
import {Observable, window} from "rxjs";
import {AuthService} from "../../../core/01-auth/services/auth.service";
import {NgClass, NgStyle} from "@angular/common";
import {Router} from "@angular/router";
import {User} from "../../models/user-data.model";

@Component({
  selector: 'app-navbar',
  standalone: true,
    imports: [
        NgStyle,
        NgClass
    ],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss'

})
export class NavbarComponent {
    user: User | null = null;
    isAuth : boolean = false;
    isMobile: boolean = false;
    menuVisible: boolean = false;
    hidden: boolean = true;
    public authService = inject(AuthService);
    private router = inject(Router);

    constructor() {
        // this.user = this.authService.user$;
        effect(() => {
            this.isAuth = this.authService.isAuth()
            this.user = this.authService.user();
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        // @ts-ignore
        this.isMobile = window.innerWidth <= 768; // Definir el breakpoint que consideras como móvil
        if (!this.isMobile) {
            this.menuVisible = false; // Ocultar el menú en modo desktop
        }
    }

    // Ocultará cuando se haga clic fuera del menu o en una de las opciones.
    @HostListener('document:click', ['$event'])
    handleClick(event: MouseEvent) {
        const menu = document.getElementById('id-button-menu');
        if (menu && !menu.contains(event.target as Node)) {
            this.menuVisible = false;
        }
    }

    toggleMenu() {
        this.menuVisible = !this.menuVisible;
    }

    redirectoToHome() {
        this.router.navigate(['/tus-polizas']);
    }

    signOff() {
        this.authService.logout();
    }
}
