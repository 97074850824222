import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Utils} from "../util/utils";
import {HttpClientOptions, HttpService} from "./http.service";
import {LocalStorageService} from "../storage/local-storage.service";

@Injectable({
    providedIn: 'root'
})
export class HttpWrapperService {
    private localStorage = inject(LocalStorageService);

    constructor(
        private http: HttpService,
    ) {
    }

    public get<T>(resource: string, options?: HttpClientOptions, handleErrors?: boolean): Observable<T> {
        return this.http.get(resource, this.setOptions(options), handleErrors);
    }

    public post<T>(resource: string, _data?: any, handleErrors?: boolean, option?: any): Observable<T> {
        let info = {
            headers: {
                'responseType': 'json',
                'observe': 'response'
            },
            body: _data || ''
        };

        if (option) {
            info = {
                ...info,
                ...option
            };
        }

        return this.http.post(resource, this.setOptions(info), handleErrors);
    }

    public put<T>(resource: string, options?: HttpClientOptions, handleErrors?: boolean): Observable<T> {
        return this.http.put(resource, this.setOptions(options), handleErrors);
    }

    public delete<T>(resource: string, options?: HttpClientOptions, handleErrors?: boolean): Observable<T> {
        /*    const auth = this.userProvider.isAuth();
            if (!auth) {
              return null;
            }*/
        return this.http.delete(resource, this.setOptions(options), handleErrors);
    }

    public patch<T>(resource: string, data: any, handleErrors?: boolean): Observable<T> {
        const info = {
            headers: {
                'content-Type': 'application/json; charset=utf-8',
            },
            body: data
        };
        return this.http.patch(resource, this.setOptions(info), handleErrors);
    }

    public newPut<T>(resource: string, data: any, handleErrors?: boolean): Observable<T> {
        const info = {
            headers: {
                'content-Type': 'application/json; charset=utf-8',
            },
            body: data
        };
        return this.http.put(resource, this.setOptions(info), handleErrors);
    }


    private setOptions(options?: HttpClientOptions): HttpClientOptions {
        // si el path no es login, agregar el token.
        // const token = this.authService.getAccessToken();
        const token = this.localStorage.getItem('access_token') || '';
        return Utils.mergeDeep(options || {}, {
            headers: {
                Authorization: 'Bearer ' + token,
            }
        });
    }
}
