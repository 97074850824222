<div class="grid grid-rows-1 grid-flow-col grid-cols-12">
    <app-stepper-header
            class="col-span-12 max-h-24"
            [title]="headerTitle()"
            [length]="headerLength()"
            [position]="headerPositionActive()"
            (changePosition)= "headerPositionChange($event)"
    ></app-stepper-header>
</div>

<div class="h-auto flex flex-col lg:flex-row">
    <app-stepper-map
            class="col-span-12 lg:col-span-3"
            [title]="mapTitle()"
            [steps]="mapSteps"
    ></app-stepper-map>
    <app-stepper-body style="height: auto"
            class="w-[95vw] lg:w-[70vw] col-span-12 lg:col-span-9"
            [activeComponent]="activeComponent"
            [activeComponentChange]="activeComponentChange"
    ></app-stepper-body>
</div>
