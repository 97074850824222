import {inject, Injectable} from "@angular/core";
import {BehaviorSubject, catchError, combineLatest, forkJoin, map, Observable, of, tap} from "rxjs";
import {InsurancePolicy, Partner, Product} from "./models/homeInterface";
import {LocalStorageService} from "../../core/storage/local-storage.service";
import {HttpWrapperService} from "../../core/http/http-wrapper.service";
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PolicyService {
    private httpWrapperService = inject(HttpWrapperService)
    private localStorage = inject(LocalStorageService);

    constructor(private http: HttpClient) {}

    loadInsurances() {
        const certificates = this.localStorage.getItem<InsurancePolicy[]>("certificates");
        if (certificates) {
            return new BehaviorSubject(certificates);
        }
        const user = this.localStorage.getItem("user");
        // todo validar que exista el usuario, sino clear de todo el local y redirigir al login.
        // @ts-ignore
        const docType = user?.document_type;
        // @ts-ignore
        const docNumber = user?.document_number;
        // @ts-ignore
        const gender = user?.gender;
        const url = `/policy?document_type=${docType}&document_number=${docNumber}&gender=${gender}`;
        return this.httpWrapperService.get<InsurancePolicy[]>(url).pipe(
            tap((response: any) => {
                this.localStorage.setItem<InsurancePolicy[]>("certificates", response);
            }));
    }

    uploadImages(insurances: InsurancePolicy[]) {
        insurances.forEach(insurance => {
            combineLatest([this.requestImagesForPartners(insurance.partner.cuit),
                this.requestImagesForProducts(insurance.product)
            ]).subscribe(([imagePartner, imageProduct]) => {
                insurance.partner.image = imagePartner == null? "" : imagePartner.image;
                insurance.url_logo = imageProduct  == null? "" : imageProduct.image;
            });
        });
        return insurances;
    }

    requestImagesForPartners(cuit: string): Observable<Partner | null> {
        const url = `/partners/read/cuit/${cuit}`;
        return this.httpWrapperService.get<Partner>(url,undefined, false).pipe(
            catchError(() => of(null))
        );
    }
    requestImagesForProducts(name: string): Observable<Product | null> {
        const url = `/products/read/product-name/${name}`;
        return this.httpWrapperService.get<Product>(url, undefined, false).pipe(
            catchError(() => of(null))
        );
    }

    setSelectedPolicy($event: InsurancePolicy) {
        this.localStorage.setItem<InsurancePolicy>("selectedPolicy", $event);
    }

    getSelectedPolicy() {
        return this.localStorage.getItem<InsurancePolicy>("selectedPolicy");
    }

    downloadPolicy(policy: InsurancePolicy) {
        const data:any = {
            policy : policy.official_policy_number.toString(),
            certificate : policy.official_certificate_number != null ? policy.official_certificate_number : 0
        }
        const url = `/policy/download`;
        const option: any = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/pdf'
            },
            responseType: 'blob'
        }

        return this.httpWrapperService.post<BlobPart>(url,data, false, option).pipe(
            map((res) => {
                return new Blob([res], { type: 'application/pdf' });
            }),
            catchError(() => of(null))
        );
    }
}
