import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";

export interface DataIn {
    status: Status,
    title: string | undefined,
    subTitle: string | undefined,
    message: string | undefined,

    showCancelButton: boolean | undefined,
    showAcceptButton: boolean | undefined,
    showRedirectButton: boolean | undefined,
    primaryButton: string | undefined,
    redirectTo: string | undefined,

    showActionButton: boolean | undefined,
    actionButton: string | undefined,

    autoClose: boolean | undefined,
    timeOur: number | undefined,
}

export enum Status {
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
    WARNING = 'WARNING',
}

@Component({
    standalone: true,
    selector: 'app-custom-dialog',
    templateUrl: './custom-dialog.component.html',
    styleUrls: ['./custom-dialog.component.scss']
})


export class CustomDialogComponent implements OnInit{

    constructor(
        @Inject(MAT_DIALOG_DATA) public _data: DataIn,
        private matDialogRef: MatDialogRef<CustomDialogComponent>,
        private _router: Router,
    ) {}

    ngOnInit(): void {
        if (this._data.autoClose) {
            setTimeout(() => {
                this.closemodal();
            }, this._data.timeOur);
        }
    }


    closemodal() {
        this.matDialogRef.close(true);
        this.matDialogRef.afterClosed().subscribe((result: any) => {
            if (this._data.showCancelButton) {
                const uri = this._data.redirectTo ? this._data.redirectTo : '/tus-polizas';
                this._router.navigate([uri]);
            }

        });
    }

    redirectTo() {
        this.matDialogRef.close();
        this.matDialogRef.afterClosed().subscribe((result: any) => {
            const uri = this._data.redirectTo ? this._data.redirectTo : '/tus-polizas';
            this._router.navigate([uri]);
        });
    }

    actionButton() {
        this.matDialogRef.close('RETRY');
    }
}
