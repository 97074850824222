<section class="h-full main-screen w-screen">
    <nav class="h-full bg-gray-200 border-gray-200 dark:bg-gray-900">
        <div class=" flex flex-nowrap items-center justify-between mx-auto p-4 ">
            <a class="flex items-center space-x-3 rtl:space-x-reverse">
                <img (click)="redirectoToHome()" src="../../assets/logoLife.png" class="h-8" alt="Flowbite Logo"/>
                <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white"></span>
                <div class="hidden lg:inline-flex">
<!--                    <span class="mx-2">|</span>-->
<!--                    <p class="mx-2">Tu seguro online</p>-->
                </div>
            </a>
            @if (isAuth) {
                <div class="flex items-center md:order-2 space-x-1 md:space-x-0  px-4 rtl:space-x-reverse">
                    <button type="button" data-dropdown-toggle="language-dropdown-menu"
                            (click)="toggleMenu()" id="id-button-menu"
                            class="inline-flex items-center font-medium justify-center px-4 py-2 text-sm text-gray-900 dark:text-white rounded-lg cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-700 dark:hover:text-white">
                        <img src="../../assets/icons/person-shape.png" class="w-8 h-8 rounded-full me-1"/>
                        {{user ? user.name + ' ' + user.lastname: ''}}
                    </button>
                    @if (menuVisible) {
                        <div class="absolute top-12 z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700">
                            <ul class="py-2 font-medium" role="none">
                                <li>
                                    <a href="#"
                                       class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                                       role="menuitem">
                                        <div class="inline-flex items-center">
                                            <p>Mi perfil</p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="#"
                                       (click)="signOff()"
                                       class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-600 dark:hover:text-white"
                                       role="menuitem">
                                        <div class="inline-flex items-center">
                                            <p>Cerrar sesión</p>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    }

<!--                    <div class="mega-menu">-->
<!--                        <button data-collapse-toggle="navbar-language" type="button" (click)="toggleMenu()"-->
<!--                                class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"-->
<!--                                aria-controls="navbar-language" aria-expanded="false">-->
<!--                            <span class="sr-only">Open main menu</span>-->
<!--                            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"-->
<!--                                 viewBox="0 0 17 14">-->
<!--                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"-->
<!--                                      stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>-->
<!--                            </svg>-->
<!--                        </button>-->
<!--                        @if (menuVisible) {-->
<!--                            <div class="mega-menu-wrapper">-->
<!--                                <button class="mega-menu-toggle" (click)="toggleMenu()">-->
<!--                                    <span class="sr-only">Open main menu</span>-->
<!--                                </button>-->
<!--                                <div class="mega-menu-content" [class.open]="menuVisible">-->
<!--                                    <button class="close-menu" (click)="toggleMenu()">X</button>-->
<!--                                    <ul class="menu-options">-->
<!--                                        <li><a href="#">-->
<!--                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">-->
<!--                                                <path d="M0 0h24v24H0z" fill="none"/>-->
<!--                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-3c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4zm0-6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>-->
<!--                                            </svg>-->
<!--                                            Mi Perfil</a></li>-->
<!--                                        <li><a href="#">-->
<!--                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">-->
<!--                                                <path d="M0 0h24v24H0z" fill="none"/>-->
<!--                                                <path d="M9 16h6v-2H9v2zm7-11h-4c-1.1 0-1.99.9-1.99 2L10 7h4c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1h-4l1.01 1c.01.07.01.14.01.21 0 .82-.67 1.5-1.5 1.5S9 18.82 9 18H7c0 1.1.9 2 2 2h4c1.1 0 2-.9 2-2v-1.79c2.93-.55 5-3.21 5-6.21 0-3.31-2.69-6-6-6zm0 4c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3z"/>-->
<!--                                            </svg>-->
<!--                                            Cerrar Sesión</a></li>-->
<!--                                        <li><a href="#">-->
<!--                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">-->
<!--                                                <path d="M0 0h24v24H0z" fill="none"/>-->
<!--                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-3c-.56 0-1.13.05-1.69.15l-.57-.54C8.67 15.67 8 14.38 8 13c0-1.61.83-2.99 2.08-3.82l.54.57c-.43.53-.62 1.22-.5 1.95.07.56.36 1.07.78 1.49.9.9 2.24.9 3.14 0 .9-.9.9-2.24 0-3.14-.43-.43-.93-.71-1.49-.78-.73-.11-1.42.07-1.95.5l-.54-.57C12.01 8.83 13.39 8 15 8c1.38 0 2.67.67 3.48 1.77l.57.54c-.1.56-.15 1.13-.15 1.69 0 2.76 2.24 5 5 5s5-2.24 5-5-2.24-5-5-5c-1.22 0-2.38.45-3.29 1.26l-.54-.57C16.67 6.83 17.95 6 19 6c1.61 0 3 1.31 3 2.92 0 1.27-.79 2.39-1.99 2.83l-.57-.54c-.69-.65-1.59-1.01-2.54-1.01-.96 0-1.86.36-2.55 1.02l-.54.54c-1.2-.44-2-1.56-2-2.82 0-1.61.83-2.99 2.08-3.82l.54.57c-.43.53-.62 1.22-.5 1.95.07.56.36 1.07.78 1.49.9.9 2.24.9 3.14 0 .9-.9.9-2.24 0-3.14-.43-.43-.93-.71-1.49-.78-.73-.11-1.42.07-1.95.5l-.57-.54C13.13 3.83 12.58 3 12 3c-1.61 0-3 1.31-3 2.92 0 .52.15 1 .41 1.42l-1.83 1.83c-.39.39-.39 1.03 0 1.42l2.83 2.83c.39.39 1.02.39 1.41 0l1.83-1.83c.42.26.91.41 1.42.41 1.61 0 2.92-1.39 2.92-3S13.61 5 12 5z"/>-->
<!--                                            </svg>-->
<!--                                            Gestionar un Trámite</a></li>-->
<!--                                        <li><a href="#">-->
<!--                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">-->
<!--                                                <path d="M0 0h24v24H0z" fill="none"/>-->
<!--                                                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-3c-.56 0-1.13.05-1.69.15l-.57-.54C8.67 15.67 8 14.38 8 13c0-1.61.83-2.99 2.08-3.82l.54.57c-.43.53-.62 1.22-.5 1.95.07.56.36 1.07.78 1.49.9.9 2.24.9 3.14 0 .9-.9.9-2.24 0-3.14-.43-.43-.93-.71-1.49-.78-.73-.11-1.42.07-1.95.5l-.54-.57C12.01 8.83 13.39 8 15 8c1.38 0 2.67.67 3.48 1.77l.57.54c-.1.56-.15 1.13-.15 1.69 0 2.76 2.24 5 5 5s5-2.24 5-5-2.24-5-5-5c-1.22 0-2.38.45-3.29 1.26l-.54-.57C16.67 6.83 17.95 6 19 6c1.61 0 3 1.31 3 2.92 0 1.27-.79 2.39-1.99 2.83l-.57-.54c-.69-.65-1.59-1.01-2.54-1.01-.96 0-1.86.36-2.55 1.02l-.54.54c-1.2-.44-2-1.56-2-2.82 0-1.61.83-2.99 2.08-3.82l.54.57c-.43.53-.62 1.22-.5 1.95.07.56.36 1.07.78 1.49.9.9 2.24.9 3.14 0 .9-.9.9-2.24 0-3.14-.43-.43-.93-.71-1.49-.78-.73-.11-1.42.07-1.95.5l-.57-.54C13.13 3.83 12.58 3 12 3c-1.61 0-3 1.31-3 2.92 0 .52.15 1 .41 1.42l-1.83 1.83c-.39.39-.39 1.03 0 1.42l2.83 2.83c.39.39 1.02.39 1.41 0l1.83-1.83c.42.26.91.41 1.42.41 1.61 0 2.92-1.39 2.92-3S13.61 5 12 5z"/>-->
<!--                                            </svg>-->
<!--                                            Preguntas Frecuentes</a></li>-->
<!--                                    </ul>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        }-->
<!--                    </div>-->
                </div>
            }
            <div class="hidden md:inline-flex ml-auto space-x-2">
                <div>
                    <p class="text-gray-800 dark:text-white text-[14px]">Preguntas</p>
                    <p class="text-gray-800 dark:text-white text-[14px]">frecuentes</p>
                </div>
                <div style="display: grid;place-content: center">
                    <img src="../../assets/icons/help.png" class="w-6 h-6 rounded-full mx-auto mt-2" alt="Help Icon"/>
                </div>
            </div>
        </div>
    </nav>

</section>

