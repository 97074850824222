import {Component, HostListener, inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {DashboardService} from "../../../../dashboard.service";
import {VALIDATION_MESSAGES} from "../../../../shared/consts/validations.const";
import {AlertsComponent} from "../../../../shared/components/alerts/alerts.component"
import {PopupsComponent} from "../../../../shared/components/popups/popups.component"
import {CreateAccountService} from "../../create-account.service";
import {environment} from "../../../../../environments/environment";
import {LocalStorageService} from "../../../../core/storage/local-storage.service";
import {User} from "../../../../shared/models/user-data.model";

@Component({
    selector: 'app-form-create-account',
    standalone: true,
    imports: [ReactiveFormsModule, AlertsComponent, PopupsComponent],
    templateUrl: './form-create-account.component.html',
    styleUrl: './form-create-account.component.scss'
})
export class FormCreateAccountComponent implements OnInit {

    private dashboardService = inject(DashboardService);
    isLoading: boolean = false;
    private localStorage = inject(LocalStorageService);
    createAccountService = inject((CreateAccountService))
    validationMessages = VALIDATION_MESSAGES;
    personalDataForm: FormGroup = this.dashboardService.formAlta.get('personalData') as FormGroup;

    ngOnInit(): void {
        this.loadFormData();
    }

    loadFormData() {
        const formData = this.dashboardService.getFormValues('personalData');
        this.personalDataForm.patchValue(formData);
    }

    public getValidationPersonalFromControl(validation: any, elem: string): any {
        try {
            //@ts-ignore
            return this.personalDataForm.get(elem).invalid && this.personalDataForm.get(elem).hasError(validation.type) && (this.personalDataForm.get(elem).dirty || this.personalDataForm.get(elem).touched)
        } catch (e) {
            return false;
        }
    }

    onContinue() {
        this.isLoading = true;
        this.dashboardService.toogleLoading(this.isLoading);
        if (this.personalDataForm.invalid) {
            this.personalDataForm.markAllAsTouched();
            this.isLoading = false;
            return;
        }
        const personalData = this.personalDataForm.value;

        this.maintainPernalData(personalData);
        this.createAccountService.$personalData.next(personalData)
        this.dashboardService.$isLoading.subscribe((isLoading: boolean) => {
            this.isLoading = isLoading;
        });
    }

    private maintainPernalData(personalData: any) {
        // let userData: User | null = this.localStorageService.getItem("user")

        const personalDataSnakeCase = {
            // uid: (userData !== null) ? userData.uid : "",
            uid: "",
            lastname: personalData.last_name,
            name: personalData.name,
            document_type: personalData.document_type,
            document_number: parseInt(personalData.document_number),
            email: personalData.email,
            gender: personalData.gender,
            accept_terms: true
        }

        this.localStorage.setItem("user", personalDataSnakeCase);
        this.dashboardService.setFormValues('personalData', personalData);
    }

    @HostListener('window:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        if (event.ctrlKey && event.key === '1') {
            this.fillFormWithExampleData();
        }
    }

    fillFormWithExampleData() {
        if (!environment.production) {
            this.personalDataForm.setValue({
                last_name: 'Palet',
                name: 'Maxi',
                document_type: 'DNI',
                document_number: '12345678',
                gender: 'M',
                email: 'm.l.palet@gmail.com',
                acceptTerms: true
            });
        }
    }
}
