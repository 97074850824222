import {Component, inject} from '@angular/core';
import {LoginFormComponent} from "./components/01-login-form/login-form.component";
import {AuthService} from "../../core/01-auth/services/auth.service";
import {Router} from "@angular/router";
import {PopupsComponent} from "../../shared/components/popups/popups.component";
import {HttpWrapperService} from "../../core/http/http-wrapper.service";
import {DialogService} from "../../shared/components/custom-dialog/dialog.service";
import {LocalStorageService} from "../../core/storage/local-storage.service";
import {DashboardService} from "../../dashboard.service";
import {FooterComponent} from "../../shared/components/footer/footer.component";

@Component({
    selector: 'app-login',
    standalone: true,
    imports: [
        LoginFormComponent,
        PopupsComponent,
        FooterComponent
    ],
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss'
})
export class LoginComponent {
    private router = inject(Router);
    private authService = inject(AuthService);
    private httpWrapperService = inject(HttpWrapperService);
    private customDialogService = inject(DialogService);
    private localStorageService = inject(LocalStorageService);
    private dashboardService = inject(DashboardService);
    isLoading: boolean = false;

    constructor() {
        this.localStorageService.clear();
    }

    getLogin(credentials: { email: string, password: string }) {
        this.httpWrapperService.post('/login', credentials).subscribe({
                next: response => {
                    // loading paso-3: apagar el loading
                    this.dashboardService.toogleLoading(false);

                    this.authService.login(response);
                    this.router.navigate(['/tus-polizas']);
                },
                error: error => {
                    this.dashboardService.toogleLoading(false);
                    if (error.message === 'ERROR_RESOLVED') {
                        this.dashboardService.toogleLoading(false);
                        return;
                    }
                    this.customDialogService.errorLogin("¡Ooops! Hubo un error", error.message);
                }
            }
        );
    }
}
