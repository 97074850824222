import {inject, Injectable, signal} from '@angular/core';
import {LocalStorageService} from "../../storage/local-storage.service";
import {Router} from "@angular/router";
import {DialogService} from "../../../shared/components/custom-dialog/dialog.service";
import {HttpWrapperService} from "../../http/http-wrapper.service";
import {BehaviorSubject, of, Subject, take, takeUntil, timer} from "rxjs";
import {HttpClientOptions} from "../../http/http.service";
import {User} from "../../../shared/models/user-data.model";
import {toObservable} from "@angular/core/rxjs-interop";

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    isAuth = signal<boolean>(false);
    customDialogService = inject(DialogService);
    httpWrapperService = inject(HttpWrapperService);

    private localStorage = inject(LocalStorageService);
    private router = inject(Router);
    // user: any | undefined;
    user = signal<User | null>(null);
    user$ = toObservable(this.user).subscribe();

    public isAuthenticated(): void {
        const accessToken = this.localStorage.getItem('access_token');
        const refreshToken = this.localStorage.getItem('refresh_token');
        const expires = this.localStorage.getItem('expires');
        const user = this.localStorage.getItem<User>('user');
        if (accessToken && refreshToken && user) {
            // todo: llamar a backend para validar token.
            this.user.set(user);
            this.isAuth.set(true);
        } else {
            this.isAuth.update(() => false);
        }
    }

    logout() {
        // todo: agregar clear de localstorage.
        this.isAuth.update(() => false);
        this.localStorage.clear();
        this.router.navigateByUrl('/login').then();
    }

    login(loginData: any): void {
        this.localStorage.setItem('access_token', loginData.authentication.access_token);
        this.localStorage.setItem('refresh_token', loginData.authentication.refresh_token);
        this.localStorage.setItem('expires_in', loginData.authentication.expires_in);
        this.localStorage.setItem('scope', loginData.authentication.scope);
        this.localStorage.setItem<User>('user', loginData.user);
        this.isAuthenticated();
        this.createSession(loginData.authentication);
    }

    private createSession(loginData: any) {
        let expires = this.localStorage.getItem('expires') as Date | null;

        if (expires) {
            expires = new Date(expires);
        } else {
            expires = new Date(new Date().getTime() + loginData.expires_in * 1000);
            this.localStorage.setItem('expires', expires);
        }

        // obtener milisegundos de la diferencia entre la fecha de expiración y la fecha actual.
        const timeoutDuration = expires.getTime() - new Date().getTime() - 60000;
        if(timeoutDuration <= 0) {
            this.refreshToken();
            return;
        }

        const cancelSignal$ = new Subject(); // Creamos un subject que actuará como señal de cancelación para timer$

        setTimeout(() => {
            const timer$ = timer(60000); // 60 segundos
            const dialogRef = this.customDialogService.refreshToken();

            dialogRef.afterClosed().pipe(
                takeUntil(timer$) // En 60 segundos se activa timer$
            ).subscribe(result => {
                if (result) {
                    cancelSignal$.next(undefined); // Frenar timer$
                    this.refreshToken();
                } else {
                    this.logout();
                }
            });

            timer$.pipe(
                takeUntil(cancelSignal$)
            ).subscribe(() => {
                dialogRef.close();
                this.logout();
            });
        }, timeoutDuration);
    }

    private refreshToken() {
        const refreshToken = this.localStorage.getItem('refresh_token') as string;

        this.localStorage.clear();

        // set refresh token en headers
        const httpOptions: HttpClientOptions = {
            headers: {
                'refreshToken': refreshToken
            }
        }

        this.httpWrapperService.get('/login/refresh', httpOptions).subscribe({
            next: response => {
                this.login(response);
            },
            error: error => {
                if (error.status === 401) {
                    this.customDialogService.error401();
                } else {
                    this.customDialogService.error500();
                }
                this.logout();
            }
        });
    }

    getAccessToken(): string {
        return <string>this.localStorage.getItem('access_token') || '';
    }

    // getUser(): any {
    //     if (!this.user) {
    //         this.user.set(()=>this.localStorage.getItem<User>('user'));
    //     }
    //     return this.user;
    // }
}
