import {
    AfterViewInit, ChangeDetectorRef,
    Component,
    ComponentFactoryResolver, EventEmitter,
    inject,
    input,
    Input,
    OnInit, output, Output, Type,
    ViewChild,
    ViewContainerRef
} from '@angular/core';
import {
    FormCreateAccountComponent
} from '../../../../../features/03-create-account/components/form-create-account/form-create-account.component'
import {StepperStatus} from "../../models/mapStepper.interface";
import {
    FormCompleteAccountComponent
} from "../../../../../features/03-create-account/components/form-complete-account/form-complete-account.component";
import {
    SendEmailFormComponent
} from "../../../../../features/04-forgot-password/components/send-email-form/send-email-form.component";
import {BehaviorSubject} from "rxjs";
import {Router} from "@angular/router";

@Component({
    selector: 'app-stepper-body',
    standalone: true,
    imports: [FormCreateAccountComponent, FormCompleteAccountComponent],
    templateUrl: './stepper-body.component.html',
    styleUrl: './stepper-body.component.scss'
})
export class StepperBodyComponent implements OnInit, AfterViewInit {
    private router = inject(Router);
    @ViewChild('dynamicComponentContainer', {read: ViewContainerRef}) container: ViewContainerRef | undefined;
    protected readonly StepperStatus = StepperStatus;
    activeComponent = input.required<any>();
    activeComponent2: any;
    // emitterInfo = output<any>();
    @Output() emitterInfo = new EventEmitter<unknown>();
    @Input() activeComponentChange!: BehaviorSubject<Type<any>>;
    isFirst = true;
    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private cdr: ChangeDetectorRef
    ) {
    }

    ngAfterViewInit(): void {
        this.loadDynamicComponent();
    }

    ngOnInit() {
        this.activeComponentChange.subscribe((component: any) => {
            if (component === null) {
                return;
            }
            this.activeComponent2 = component;
            this.cdr.detectChanges();
            if (this.container && !this.isFirst) {
                this.loadDynamicComponent2();
            }
        });
    }

    loadDynamicComponent() {
        this.container!.clear();
        try {
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.activeComponent());
            const componentRef = this.container!.createComponent(componentFactory);
        } catch (e) {
            this.router.navigate(['/login']);
        }
        this.cdr.detectChanges();
        this.isFirst = false;
    }

    loadDynamicComponent2() {
        this.container!.clear();
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.activeComponent2);
        const componentRef = this.container!.createComponent(componentFactory);
    }

    onEmitInfo($event: any) {
        this.emitterInfo.emit($event);
    }

}
