import {Component, inject, OnInit, output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {DashboardService} from "../../../../dashboard.service";
import {VALIDATION_MESSAGES} from "../../../../shared/consts/validations.const";
import {AlertsComponent} from "../../../../shared/components/alerts/alerts.component";
import {ForgotPasswordService} from "../../forgot-password.service";
import {AsyncPipe} from "@angular/common";
import {Observable} from "rxjs";

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [AlertsComponent,ReactiveFormsModule,AsyncPipe],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent implements OnInit{
  dashboardService = inject(DashboardService);
  $isLoading: Observable<boolean> | undefined;

  mostrarAlerta: boolean = false;
  alertMessage: string = "";
  public changePasswordForm: FormGroup<any> = this.dashboardService.formAlta.get('changePasswordForm') as FormGroup;
  public validationMessages = VALIDATION_MESSAGES;
  forgotPasswordService = inject(ForgotPasswordService);
  isLoading: boolean = false;
  public showPassword: boolean=false;
  public showConfirmPassword: boolean=false;


  ngOnInit(): void {
    this.$isLoading = this.forgotPasswordService.$isLoading;

    this.validadForm();

  }
  validadForm() {
    this.changePasswordForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?])[A-Za-z\\d!@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?]{8,16}$')
      ]),
      confirmPassword: new FormControl('', Validators.required),

  });
  }



  emitForm() {
    this.forgotPasswordService.changeIsLoading(true);

    console.log(this.changePasswordForm);
    if (this.changePasswordForm.invalid) {
        this.changePasswordForm.markAllAsTouched();
        return;
    }
    this.isLoading = true;
    const password = this.changePasswordForm.get('password')?.value;
    const confirmPassword = this.changePasswordForm.get('confirmPassword')?.value;
    console.log(this.changePasswordForm);
    if (this.changePasswordForm.invalid || password!==confirmPassword ) {
      this.changePasswordForm.markAllAsTouched();
      if (this.changePasswordForm.invalid) {
        this.mostrarAlerta = true;
        this.alertMessage = "complete el formulario";
        return;
      }
      else  if(password!==confirmPassword){
        this.mostrarAlerta = true;
        this.alertMessage = "las contraseñas no coinciden";
        return;
      }
    }

    this.forgotPasswordService.$password.next(password);
  }

  public getValidationChangePasswordFromControl(validation: any, elem: string): any {
    try {
      //@ts-ignore
      return this.changePasswordForm.get(elem).invalid && this.changePasswordForm.get(elem).hasError(validation.type) && (this.changePasswordForm.get(elem).dirty || this.changePasswordForm.get(elem).touched)
    } catch (e) {
      return false;
    }
  }

  getConfirmPass(){
    const pass1=this.changePasswordForm.get('password')?.value;
    const pass2=this.changePasswordForm.get('confirmPassword')?.value;
  return (pass1===pass2)? false:true;
  }


  checkIsDisabled() {
    // Validar si el formulario es valido y si password y confirmPassword son iguales y no estan vacios
    if (this.changePasswordForm.invalid) {
      return true;
    }
    const password = this.changePasswordForm.get('password')?.value;
    const confirmPassword = this.changePasswordForm.get('confirmPassword')?.value;
    if ( (password == "" && confirmPassword == "") || password !== confirmPassword) {
      return true;
    }
    return false;
  }


}
