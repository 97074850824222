import {Component, input, OnInit} from '@angular/core';
import {StepperStatus} from "../../models/mapStepper.interface";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {CommonModule} from '@angular/common';

@Component({
    selector: 'app-stepper-map',
    standalone: true,
    imports: [
        CommonModule
    ],
    templateUrl: './stepper-map.component.html',
    styleUrl: './stepper-map.component.scss',
    animations: [
        trigger('collapse', [
            state('open', style({
                opacity: 1,
                transform: 'translateY(0)'
            })),
            state('closed', style({
                opacity: 0,
                transform: 'translateY(-100%)'
            })),
            transition('open => closed', animate('0.3s')),
            transition('closed => open', animate('0.3s'))
        ])
    ]
})
export class StepperMapComponent implements OnInit{
    title = input.required<string>();
    steps = input.required<any[]>();
    protected readonly StepperStatus = StepperStatus;
    isVisible = true;
    animationState = 'open';

    collapseElement(): void {
        if (this.animationState === 'open') {
            this.animationState = 'closed';
            return;
        }
        if (this.animationState === 'closed') {
            this.animationState = 'open';
        }
    }

    animationDone(): void {
        if (this.animationState === 'closed') {
            this.isVisible = false;
        }
    }

    ngOnInit(): void {
        console.log(this.steps()[0]);
    }
}
