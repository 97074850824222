import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    private context: 'create-account' | 'forgot-password' | null = null;

    setContext(context: 'create-account' | 'forgot-password') {
        this.context = context;
    }

    getContext(): 'create-account' | 'forgot-password' | null {
        return this.context;
    }
}
