<section class="h-full w-screen">
    <div style="overflow-y: hidden" class="w-full h-full grid grid-cols-12 gap-2 md:gap-4 lg:gap-8 img">
        <div class="hidden col-span-12 md:col-span-6 md:mb-16 md:flex md:flex-col md:items-center md:justify-end md:pl-16 lg:pl-16">
            <h1 class="text-white md:text-3xl lg:text-4xl font-bold mb-2">Ahora podés gestionar tus seguros desde tu casa.</h1>
            <p class="text-white text-lg">
                Esta plataforma te permite ver el detalle de tus coberturas, iniciar tus trámites y ver el estado de los mismos.
            </p>
        </div>
        <div class="login-form col-span-12 md:col-span-6 flex items-center justify-center md:pr-6 ">
            <app-login-form (authInfo)="getLogin($event)"></app-login-form>
        </div>
    </div>
</section>
