import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    public formAlta: FormGroup = this.buildForm();
    $isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


    constructor(private formBuilder: FormBuilder) {
        this.setupFormListeners();
    }

    buildForm() {
        return this.formBuilder.group(
            {
                personalData: this.buildPersonalDataForm(),
                completeData: this.buildCompleteDataForm(),
                sendEmail: {
                    email: ['', Validators.required],
                },
                changePasswordForm: {
                    password: ['', Validators.required],
                    confirmPassword: ['', Validators.required],
                },
                validateAccount: this.formBuilder.group({}),
                pinForm: this.buildPinForm()
            }
        )
    }

    toogleLoading(isLoading: boolean) {
        this.$isLoading.next(isLoading);
    }

    get isLoading() {
        return this.$isLoading;
    }

    getFormValues(section: string): any {
        return this.formAlta.get(section)?.value;
    }

    setFormValues(section: string, values: any) {
        this.formAlta.get(section)?.patchValue(values);
    }

    buildPersonalDataForm() {
        return this.formBuilder.group({
            last_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern(/^[a-zA-Zñáéíóú ']+$/)]],
            name: ['', [Validators.required, Validators.minLength(3), Validators.pattern(/^[a-zA-Zñáéíóú ]+$/)]],
            document_type: ['', [Validators.required]],
            document_number: ['', [Validators.required, Validators.minLength(7), Validators.maxLength(10), Validators.pattern(/^[0-9]*$/)]],
            gender: ['', [Validators.required]],
            email: ['', [Validators.required, Validators.email]],
            acceptTerms: [false, [Validators.requiredTrue]]
        });
    }

    buildPinForm() {
        return this.formBuilder.group({
            pin1: ['', [Validators.required, Validators.pattern(/^\d$/)]],
            pin2: ['', [Validators.required, Validators.pattern(/^\d$/)]],
            pin3: ['', [Validators.required, Validators.pattern(/^\d$/)]],
            pin4: ['', [Validators.required, Validators.pattern(/^\d$/)]],
            pin5: ['', [Validators.required, Validators.pattern(/^\d$/)]],
            pin6: ['', [Validators.required, Validators.pattern(/^\d$/)]],
            pin7: ['', [Validators.required, Validators.pattern(/^\d$/)]],
            pin8: ['', [Validators.required, Validators.pattern(/^\d$/)]],
        });
    }

    buildCompleteDataForm() {
        return this.formBuilder.group({
            name: ['', Validators.required],
            last_name: ['', Validators.required],
            document_number: ['', Validators.required],
            document_type: ['', Validators.required],
            gender: ['', Validators.required],
            email: ['', Validators.required],
            telephone_number: ['', [Validators.required, Validators.maxLength(10), Validators.pattern('^[0-9]{0,10}$')]],
            address: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9\\s]*$')]],
            province: ['', Validators.required],
            zip: ['', [Validators.required, Validators.pattern('^\\d{0,4}$')]],
            password: ['', [
                Validators.required,
                Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?])[A-Za-z\\d!@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?]{8,16}$')
            ]],
            confirm_password: ['', Validators.required],
        });
    }

    setupFormListeners() {
        const personalDataForm = this.formAlta.get('personalData') as FormGroup;
        const completeDataForm = this.formAlta.get('completeData') as FormGroup;

        personalDataForm.valueChanges.subscribe(values => {
            completeDataForm.patchValue({
                name: values.name,
                last_name: values.last_name,
                document_number: values.document_number,
                document_type: values.document_type,
                gender: values.gender,
                email: values.email
            }, { emitEvent: false });
        });
    }

    resetForms() {
        this.formAlta.reset();
        localStorage.removeItem('formAlta');
    }
}
