import {Component, Inject, inject} from '@angular/core';
import {MapStepperInterface, StepperStatus} from "../../shared/components/stepper/models/mapStepper.interface";
import {StepperComponent} from "../../shared/components/stepper/stepper.component";
import {SendEmailFormComponent} from "./components/send-email-form/send-email-form.component";
import {ChangePasswordComponent} from "./components/change-password/change-password.component";
import {ForgotPasswordService} from "./forgot-password.service";
import {BehaviorSubject, takeUntil} from "rxjs";
import {ValidatePinComponent} from "../../shared/components/validate-pin/validate-pin.component";
import {Router} from "@angular/router";
import {AuthService} from "../../core/01-auth/services/auth.service";
import {CustomDialogComponent} from "../../shared/components/custom-dialog/custom-dialog.component";
import {DialogService} from "../../shared/components/custom-dialog/dialog.service";
import {DashboardService} from "../../dashboard.service";
import {SharedService} from "../../shared/shared.service";

@Component({
    selector: 'app-forgot-password',
    standalone: true,
    imports: [StepperComponent, SendEmailFormComponent],
    templateUrl: './forgot-password.component.html',
    styleUrl: './forgot-password.component.scss'
})
export class ForgotPasswordComponent {

    forgotPasswordService = inject(ForgotPasswordService);
    dialogService = inject(DialogService);
    dashboardService = inject(DashboardService);
    sharedService = inject(SharedService);
    router = inject(Router);

    mapSteps: MapStepperInterface[] = [
        {
            title: "Ingresar email",
            description: "step 1 description ",
            component: SendEmailFormComponent,
            status: StepperStatus.ACTIVE,
            position: 1
        },
        {
            title: "Enviar codigo",
            description: "Step 2 description",
            component: ValidatePinComponent,
            status: StepperStatus.PENDING,
            position: 2
        },
        {
            title: "Cambiar Clave",
            description: "Step 3 description",
            component: ChangePasswordComponent,
            status: StepperStatus.PENDING,
            position: 3
        }
    ];
    positionActive: number = 1;
    $mapStepChange: BehaviorSubject<MapStepperInterface[]> = new BehaviorSubject<MapStepperInterface[]>(this.mapSteps);
    email: string = "";
    pin: string = "";

    constructor() {
        this.sharedService.setContext('forgot-password');
        this.forgotPasswordService.$email.subscribe((email: string) => this.sendEmail(email));
        this.forgotPasswordService.$pin.subscribe((pin: string) => this.sendPin(pin));
        this.forgotPasswordService.$password.subscribe((password: string) => this.changePassword(password));
    }

    private sendEmail(email: string) {
        if (!email) {
            return;
        }
        this.email = email;
        this.forgotPasswordService.sendEmail(email).subscribe((res: any) => {
            if (res) {
                this.forgotPasswordService.changeIsLoading(false);
                this.updateMapSteps(1, StepperStatus.DONE, 2, StepperStatus.ACTIVE);
            }
        }, () => {
            this.dialogService.customMessage("Error de envío", "El email ingresado no existe");
            this.forgotPasswordService.changeIsLoading(false);
        });
    }

    private sendPin(pin: string) {
        if (!pin) {
            return;
        }
        this.pin = pin;
        this.forgotPasswordService.verifyPin(pin).subscribe( {
            next: (res) => {
                if (res) {
                    this.forgotPasswordService.changeIsLoading(false);
                    this.updateMapSteps(2, StepperStatus.DONE, 3, StepperStatus.ACTIVE);
                }
            },
            error: (error) => {
                if (error.message === 'ERROR_RESOLVED') {
                    this.dashboardService.toogleLoading(false);
                    return;
                }
                const msg = error.message === 'Token expirado' ? 'Tiempo agotado' : error.message;
                const dialogRef = this.dialogService.customMessage("No pudimos completar tu cuenta", msg);
                dialogRef.afterClosed().subscribe(result => {
                    this.router.navigate(['/login']);
                });
            }


        });
    }

    changePassword(password: string) {
        if (!password) {
            return;
        }
        this.forgotPasswordService.changePassword(password).subscribe({
            next: (res) => {
                if (res) {
                    this.email = "";
                    this.pin = "";
                    this.forgotPasswordService.$email.next('');
                    this.forgotPasswordService.$pin.next('');
                    this.forgotPasswordService.$password.next('');

                    const dialogRef = this.dialogService.changePasswordOk();
                    dialogRef.afterClosed().subscribe(result => {
                        this.router.navigate(['/login']);
                    });
                }
            },
            error: (err) => {
                this.forgotPasswordService.changeIsLoading(false);
            }
        });
    }

    private updateMapSteps(stepPositionDone: number, statusDone: StepperStatus, stepPositionActive?: number, statusActive?: StepperStatus) {
        // @ts-ignore
        this.mapSteps = this.mapSteps.map(step => {
            if (step.position === stepPositionDone) {
                return { ...step, status: statusDone };
            } else if (stepPositionActive && step.position === stepPositionActive) {
                this.positionActive = step.position;
                return { ...step, status: statusActive };
            } else {
                return step;
            }
        });
        this.$mapStepChange.next(this.mapSteps);
    }

}
