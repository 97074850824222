
<div class="md:flex md:items-center md:justify-evenly px-6 md:px-0 py-6">
    <div class="text-center md:text-left mb-4 md:mb-0 md:mr-8 leading-normal">
        <h1 class="text-5xl md:text-5xl lg:text-5xl font-bold mb-2">Seguro de Hogar</h1>
        <p>Aquí encontrarás toda la información sobre tu seguro y sus</p>
        <p>coberturas además de las herramientas para administrarlo</p>
    </div>
    <div class="text-center">
        <img style="margin:auto" src="assets/icons/home.png" class="h-auto w-24 md:w-32 max-h-24 md:max-h-32" alt="Icono de hogar">
    </div>
</div>

@for(cobertura of coberturas; track cobertura; let i = $index) {
<div class="mt-8 max-w-3xl mx-auto">
<div style="margin-left:1.2rem" class="border border-transparent rounded-3xl shadow-lg">
<div class="header" style="border-radius: 15px; background: #9372f6; display: flex; align-items: center; padding: 8px 0;">
<div style="margin-left: 10px; background: #a48bf1; border-radius: 50px; border: 1px solid transparent; display: flex; justify-content: center; align-items: center; width: 60px; height: 60px;">
<img style="width: 30px; height: 30px;" src="assets/icons/coberturas.png">
</div>
<p style="margin-left: 10px; font-size: 16px; color: white;">{{cobertura.coverage}}</p>
</div>
<h3 style="text-align: center; font-size: 19px;">Suma máxima asegurada</h3>
<p style="text-align: center; font-size: 48px;">$ {{cobertura.covered_capital_amount}} </p>
<hr>
</div>
</div>
<!-- Agrega un margen inferior al último div generado por el bucle -->
@if (i === coberturas.length - 1) {
<div class="mb-8"></div>
}
        }

