import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, catchError, Observable, ObservableInput, of, tap} from "rxjs";
import {CompleteAccount, PersonalDataInterface, Question} from "./models/personalDataInterface";
import {HttpWrapperService} from "../../core/http/http-wrapper.service";
import {LocalStorageService} from "../../core/storage/local-storage.service";
import { User } from '../../shared/models/user-data.model';

@Injectable({
    providedIn: 'root'
})
export class CreateAccountService {
    private httpWrapperService = inject(HttpWrapperService)
    private localStorage = inject(LocalStorageService);
    $pin = new BehaviorSubject<string>('');
    $personalData = new BehaviorSubject<PersonalDataInterface | null>(null);
    $dataToValidate = new BehaviorSubject<Question[]>([]);
    // $completeAccount = new BehaviorSubject<CompleteAccount | null, string | null>(null);
    $completeAccount = new BehaviorSubject<{
        completeAccount: CompleteAccount,
        password: string
    } | null>(null);

    constructor() {
    }

    sendPin(email: any) {
        const url = '/users/send-pin?email=' + email;
        return this.httpWrapperService.post(url,'',false).pipe(
            tap((response: any) => {
                this.localStorage.setItem("expire_in", response.expires_in);
                this.localStorage.setItem("continuation_token", response.continuation_token);
            })
        );
    }

    sendPinRetry(email: any) {
        const url = '/users/retry-pin?email=' + email;
        return this.httpWrapperService.post(url).pipe(
            tap((response: any) => {
                this.localStorage.setItem("expire_in", response.expires_in);
                this.localStorage.setItem("continuation_token", response.continuation_token);
            })
        );
    }

    // TODO: Pasar a un servicio compartido. Se utiliza en forgot-password.service.ts tambien
    validateAccount(pin: string) {
        const url = '/users/validate-account';
        const token = this.localStorage.getItem("continuation_token");
        const user = this.localStorage.getItem("user");
        const options: any = {
            user: user,
            token: {
                oob: pin.toString(),
                continuation_token: token
            }
        }
        return this.httpWrapperService.post(url, options).pipe(
            tap((response: any) => {
                this.localStorage.removeItem("continuation_token");
                // this.localStorage.setItem("expires_in", response.token.expires_in);
                this.localStorage.setItem("continuation_token", response.token.continuation_token);
                this.localStorage.setItem("veraz_questionnaire", response.veraz_questionnaire);
            }));
    }

    validateQuestionnaire(dataToValidate: any) {
        const url = '/users/validate-questionnaire';
        const options: any = {
            application_id: dataToValidate[0].id_transaction,
            answers: dataToValidate[0].questionnaire
        }
        return this.httpWrapperService.post(url, options);
    }

    createAccount(data: any) {
        const url = '/users/create-account';
        const token = this.localStorage.getItem("continuation_token");

        const options: any = {
            user: data.completeAccount,
            continuation_token: token,
            password: data.password,
        }
        return this.httpWrapperService.post(url, options, false);
    }

    reset() {
        this.$pin = new BehaviorSubject<string>('');
        this.$personalData = new BehaviorSubject<PersonalDataInterface | null>(null);
        this.$dataToValidate = new BehaviorSubject<Question[]>([]);
        this.$completeAccount = new BehaviorSubject<{
            completeAccount: CompleteAccount,
            password: string
        } | null>(null);
        this.localStorage.clear();
    }
}
