import {Component, inject, OnInit} from '@angular/core';
import {StepperComponent} from "../../shared/components/stepper/stepper.component";
import {MapStepperInterface, StepperStatus} from "../../shared/components/stepper/models/mapStepper.interface";
import {SendEmailFormComponent} from "../04-forgot-password/components/send-email-form/send-email-form.component";
import {ValidatePinComponent} from "../../shared/components/validate-pin/validate-pin.component";
import {ChangePasswordComponent} from "../04-forgot-password/components/change-password/change-password.component";
import {BehaviorSubject} from "rxjs";
import {CoverageDetailComponent} from "./components/coverage-detail/coverage-detail.component";
import {LocalStorageService} from "../../core/storage/local-storage.service";
import {Router} from "@angular/router";
import {InsurancePolicy} from "../05-policies/models/homeInterface";
import {FooterComponent} from "../../shared/components/footer/footer.component";

@Component({
  selector: 'app-policy',
  standalone: true,
    imports: [StepperComponent, CoverageDetailComponent, FooterComponent],
  templateUrl: './policy-detail.component.html',
  styleUrl: './policy-detail.component.scss'
})
export class PolicyDetailComponent implements OnInit {
  private localStorage = inject(LocalStorageService);
  private router = inject(Router);
  selectedPolicy: InsurancePolicy | undefined;

  mapSteps: MapStepperInterface[] = [];
  positionActive: number = 1;
  $mapStepChange: BehaviorSubject<MapStepperInterface[]> = new BehaviorSubject<MapStepperInterface[]>(this.mapSteps);
    constructor() {}

  ngOnInit(): void {
    this.getPolicies();
  }

  getPolicies() {
    let json: any = this.localStorage.getItem('selectedPolicy');
    if (!json) {
      this.router.navigate(['tus-polizas']);
    }
    this.selectedPolicy = json;
    if (!this.selectedPolicy) {
      return;
    }
    this.mapSteps = [
      {
        title: "Gestion de mi poliza",
        status: StepperStatus.ACTIVE,
        subTitle: "step 1",
        description: "step 1 description ",
        component: CoverageDetailComponent,
        details: [
          {
            title: "Seguro vigente desde el día: ",
            description: this.selectedPolicy!.start_date
          },
          {
            title: "N° de póliza: ",
            description: this.selectedPolicy!.policy_id.toString()
          },
          {
            title: "Lugar donde se adquirió",
            description: this.selectedPolicy.partner.name!
          },
          {
            title: "Plan actual",
            description: this.selectedPolicy.plan
          },
          {
            title: "Medio de pago",
            description: this.selectedPolicy.payment_method
          }
        ]
      }
    ];
    this.$mapStepChange.next(this.mapSteps);
  }

}
