import {inject, Injectable} from "@angular/core";
import {CustomDialogComponent, Status} from "./custom-dialog.component";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
    ValidatePinComponent
} from "../validate-pin/validate-pin.component";

@Injectable({
    providedIn: 'root'
})
export class DialogService {
    matDialog = inject(MatDialog);
    private dialogRef: MatDialogRef<CustomDialogComponent, any> | undefined;

    private openDialog(data: any, disableClose: boolean = false): MatDialogRef<CustomDialogComponent, any> {
        return this.matDialog.open(CustomDialogComponent, {
            width: '500px',
            disableClose,
            data,
        });
    }

    private openErrorDialog(title: string, message: string, primaryButton: string = "ACEPTAR", redirectTo?: string, showAcceptButton: boolean = true, showRedirectButton: boolean = false) {
        this.openDialog({
            status: Status.ERROR,
            title,
            message,
            primaryButton,
            redirectTo,
            showAcceptButton,
            showRedirectButton
        });
    }

    errorLoginCreateUser() {
        this.openErrorDialog(
            "¡Ooops! No estás registrado",
            "Es necesario crear una cuenta para poder acceder al sitio",
            "CREAR CUENTA",
            "/create-account",
            false,
            true
        );
    }

    errorLogin(title?: string, msg?: string) {
        this.openErrorDialog(
            title || "Error",
            msg || "Ha ocurrido un error al iniciar sesión"
        );
    }

    error400(msg: string) {
        this.openErrorDialog(
            "¡Ooops! Algo salió mal",
            msg
        );
    }

    error401() {
        this.openErrorDialog(
            "¡Ooops! No estás autorizado",
            "Por favor, inicia sesión para acceder a esta sección",
            "INICIAR SESIÓN",
            "/login",
            false,
            true
        );
    }

    error404() {
        this.openErrorDialog(
            "¡Ooops! No se encontró la página",
            "La página que buscas no existe"
        );
    }

    error409(msg?: string) {
        this.openErrorDialog(
            "¡Ooops! Algo salió mal",
            msg || "Estamos teniendo problemas técnicos, por favor intenta más tarde."
        );
    }

    error500(msg?: string) {
        this.openErrorDialog(
            "¡Ooops! Algo salió mal",
            msg || "Estamos teniendo problemas técnicos, por favor intenta más tarde."
        );
    }

    errorTimeout() {
        this.openErrorDialog(
            "¡Ooops! Algo salió mal",
            "Estamos teniendo demoras, por favor intenta más tarde"
        );
    }

    refreshToken() {
        return this.openDialog({
            status: Status.SUCCESS,
            title: "¿Seguis ahí?",
            message: "Refresca la sesión para continuar navegando",
            primaryButton: "ACEPTAR",
            showAcceptButton: true,
        }, true);
    }

    changePasswordOk() {
        return this.openDialog({
            status: Status.SUCCESS,
            title: "Cambiaste tu contraseña con éxito",
            message: "Ya podes ingresar con tu nueva contraseña",
            primaryButton: "ACEPTAR",
            showAcceptButton: true,
        }, true);
    }

    customMessage(title: string, message: string) {
        return this.openDialog({
            status: Status.SUCCESS,
            title: title,
            message: message,
            primaryButton: "ACEPTAR",
            showAcceptButton: true,
        }, true);
    }

    showPinMessage() {
        return this.openDialog({
            status: Status.SUCCESS,
            title: "¡Ooops! Algo salió mal",
            message: "El código ingresado no es válido. Por favor, intenta nuevamente.",
            primaryButton: "VOLVER A INTENTAR",
            showAcceptButton: true,
            showActionButton: true,
            actionButton: "ENVIAR UN NUEVO PIN",
        });
    }
}

