import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-alerts',
  standalone: true,
  imports: [],
  templateUrl: './alerts.component.html',
  styleUrl: './alerts.component.scss'
})
export class AlertsComponent implements OnInit{
  @Input() message!: string;
  @Output() close = new EventEmitter<void>();


  alertMessage: string="";

  ngOnInit(): void {

    this.alertMessage = this.message;
  }


  closeAlert() {
    this.close.emit();
  }


}
