export interface MapStepperInterface {
    title: string;
    subTitle?: string;
    description: string;
    component: any;
    status: StepperStatus;
    position?: number;
    details?: {title: string, description: string } [];
}

export enum StepperStatus {
    ACTIVE = 'active',
    DONE = 'done',
    PENDING = 'pending'
}
