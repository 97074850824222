import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, tap} from "rxjs";
import {HttpWrapperService} from '../../core/http/http-wrapper.service';
import {LocalStorageService} from "../../core/storage/local-storage.service";
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class ForgotPasswordService {
    private isLoading = new BehaviorSubject<boolean>(false);

    // crear behaviorSubject para email
    $email = new BehaviorSubject<string>('');
    $pin = new BehaviorSubject<string>('');
    $password = new BehaviorSubject<string>('');
    httpWrapperService = inject(HttpWrapperService)
    localStorage = inject(LocalStorageService);
    readonly $isLoading = this.isLoading.asObservable();

    sendEmail(email: string) {
        const url = '/login/reset-password?email=' + email;
        return this.httpWrapperService.get(url).pipe(
            tap((response: any) => {
                this.localStorage.setItem("continuation_token", response.continuation_token);
                this.localStorage.setItem("code_length", response.code_length);
            })
        );
    }

    verifyPin(pin: any) {
        const url = '/login/send-otp';
        const token = this.localStorage.getItem("continuation_token");
        const options: any = {
            oob: pin.toString(),
            continuation_token: token
        }
        return this.httpWrapperService.post(url, options).pipe(tap((response: any) => {
            this.localStorage.removeItem("continuation_token");
            this.localStorage.setItem("expires_in", response.expires_in);
            this.localStorage.setItem("continuation_token", response.continuation_token);
        }));
    }

    changePassword(password: any) {
        const url = '/login/new-password';
        const token = this.localStorage.getItem("continuation_token");
        const options: any = {
            new_password: password,
            continuation_token: token
        }
        return this.httpWrapperService.post(url, options).pipe(tap((response) => {
            this.localStorage.removeItem("continuation_token");
            this.localStorage.removeItem("expires_in");
        }));
    }

    changeIsLoading (isLoading: boolean){
        this.isLoading.next(isLoading);
    }

}
