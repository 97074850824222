import {Component, inject, OnInit} from '@angular/core';
import {LocalStorageService} from "../../../../core/storage/local-storage.service";
import {Coverage, InsurancePolicy} from "../../../05-policies/models/homeInterface";
import {Router} from "@angular/router";
import {FooterComponent} from "../../../../shared/components/footer/footer.component";

@Component({
    selector: 'app-coverage-detail',
    standalone: true,
    imports: [
        FooterComponent
    ],
    templateUrl: './coverage-detail.component.html',
    styleUrl: './coverage-detail.component.scss'
})
export class CoverageDetailComponent implements OnInit {
    coberturas: Coverage[] = [];
    localStorage = inject(LocalStorageService);
    private router = inject(Router);
    items: any;
    selectedPolicy: InsurancePolicy | undefined;


    constructor() {

        this.getPolicies();

        this.getItems();
    }

    getPolicies() {
        let json: any = this.localStorage.getItem('selectedPolicy');
        if (!json) {
            this.router.navigate(['tus-polizas']);
        }
        this.selectedPolicy = json;
        this.coberturas = json.coverage;
    }

    ngOnInit(): void {
        this.getPolicies();
    }

    getItems() {
        this.items = [
            {
                "descripcion": "cubre",
                "detalles": [
                    "Daños estéticos",
                    "Gastos de hospedaje",
                    "Remoción de escombros",
                    "Gastos de limpieza",
                    "Gastos de limpieza",
                    "Gastos de limpieza",
                ]
            },
            {
                "descripcion": "no cubre",
                "detalles": [
                    "Daños sin relación a un incendio",
                    "Falta de bienes posterior a incendio"
                ]
            }
        ]
    }
}
