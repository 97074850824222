import { Routes } from '@angular/router';
import {LoginComponent} from "./features/01-login/login.component";
import {CreateAccountComponent} from "./features/03-create-account/create-account.component";
import {authGuard} from "./core/01-auth/guard/auth.guard";
import {ForgotPasswordComponent} from "./features/04-forgot-password/forgot-password.component";
import {PolicyComponent} from "./features/05-policies/policy.component";
import {PolicyDetailComponent} from "./features/06-policy-detail/policy-detail.component";

export const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'create-account', component: CreateAccountComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'tus-polizas', component: PolicyComponent, canActivate: [authGuard] },
    { path: 'detalle-poliza', component: PolicyDetailComponent },
    { path: '', redirectTo: '/login', pathMatch: 'full' }
];
