<!--<div class="container mx-auto flex flex-col items-center">-->
<!--    <app-navbar></app-navbar>-->
<!--    <section class="container w-screen h-screen">-->
<!--        <router-outlet></router-outlet>-->
<!--    </section>-->
<!--</div>-->


<div class="wrapper">
    <app-navbar class="navbar"></app-navbar>
    <section class="body-content">
        <router-outlet></router-outlet>
    </section>
    <app-footer class="footer"></app-footer>
</div>