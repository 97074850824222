const TYPE = {
    REQUIRED: 'required',
    PATTERN: 'pattern',
    MINLENGTH: 'minlength',
    MAXLENGTH: 'maxlength',
    EMAIL: 'email',
    TOUCHED: 'touched'
}

export const VALIDATION_MESSAGES = {
    email: [
        {
            type: TYPE.REQUIRED,
            message: 'El email es requerido'
        },
        {
            type: TYPE.EMAIL,
            message: 'Necesitas usar un email valido'
        }
    ],
    name: [
        {
            type: TYPE.REQUIRED,
            message: 'El nombre es requerido'
        },
        {
            type: TYPE.PATTERN,
            message: 'Solo se aceptan letras'
        },
        {
            type: TYPE.MINLENGTH,
            message: 'El nombre debe tener al menos 3 caracteres'
        }
    ],
    lastname: [
        {
            type: TYPE.REQUIRED,
            message: 'El apellido es requerido'
        },
        {
            type: TYPE.PATTERN,
            message: 'Solo se aceptan letras'
        },
        {
            type: TYPE.MINLENGTH,
            message: 'El apellido debe tener al menos 2 caracteres'
        },
        {
            type: TYPE.MAXLENGTH,
            message: 'El máximo permitido es 20'
        }
    ],
    document_type: [
        {
            type: TYPE.REQUIRED,
            message: 'Este campo es requerido'
        }
    ],
    document_number: [
        {
            type: TYPE.REQUIRED,
            message: 'Este campo es requerido'
        },
        {
            type: TYPE.PATTERN,
            message: 'Solo se aceptan números'
        },
        {
            type: TYPE.MINLENGTH,
            message: 'El documento debe tener un mínimo de  7 digitos'
        },
        {
            type: TYPE.MAXLENGTH,
            message: 'El máximo permitido es 8 dígitos'
        }
    ],
    gender: [
        {
            type: TYPE.REQUIRED,
            message: 'Elija una opción'
        }
    ],
    telephone_number: [
        {
            type: TYPE.REQUIRED,
            message: 'Elija una opción'
        },
        {
            type: TYPE.PATTERN,
            message: 'Ingrese solamente digitos'
        },
        {
            type: TYPE.MAXLENGTH,
            message: 'Máximo 10 dígitos'
        }
    ],
    address: [
        {
            type: TYPE.REQUIRED,
            message: 'Elija una opción'
        },
        {
            type: TYPE.PATTERN,
            message: 'Caracteres no validos'
        }
    ],
    province: [
        {
            type: TYPE.REQUIRED,
            message: 'Elija una opción'
        }
    ],
    zip: [
        {
            type: TYPE.REQUIRED,
            message: 'El campo es requerido.'
        },
        {
            type: TYPE.PATTERN,
            message: 'Ingrese hasta 4 digitos.'
        }
    ],
    password: [
        {
            type: TYPE.REQUIRED,
            message: 'Este campo es requerido'
        },
        {
            type: TYPE.PATTERN,
            message: 'Password inválido'
        },
        {
            type: TYPE.MINLENGTH,
            message: 'La contraseña debe tener al menos 8 caracteres'
        }
    ],
    confirm_password: [
        {
            type: TYPE.REQUIRED,
            message: 'Este campo es requerido'
        }

    ],
    acceptTerms: [
        {
            type: TYPE.REQUIRED,
            message: 'Aceptar términos y condiciones'
        }
    ]
};
