import {Component, inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {VALIDATION_MESSAGES} from "../../../../shared/consts/validations.const";
import {ForgotPasswordService} from '../../forgot-password.service';
import {AsyncPipe} from "@angular/common";
import {Observable} from "rxjs";

@Component({
    selector: 'app-send-email-form',
    standalone: true,
    imports: [ReactiveFormsModule, AsyncPipe],
    templateUrl: './send-email-form.component.html',
    styleUrl: './send-email-form.component.scss'
})
export class SendEmailFormComponent implements OnInit{
    $isLoading: Observable<boolean> | undefined;
    mostrarAlerta: boolean = false;
    alertMessage: string = "";
    sendEmail: FormGroup<any> = new FormGroup<any>({
        email: new FormControl('', [Validators.required, Validators.email]),
    });
    validationMessages = VALIDATION_MESSAGES;

    forgotPasswordService = inject(ForgotPasswordService);
    // isLoading: boolean = false;
    constructor() {
    }

    ngOnInit(): void {
        this.$isLoading = this.forgotPasswordService.$isLoading;
    }

    public getValidationSendEmailFromControl(validation: any, elem: string): any {
        let response = this.sendEmail.get(elem);
        if (response != null) {
            return response.invalid && response.hasError(validation.type) && (response.dirty || response.touched);
        } else {
            return true;
        }
    }

    onContinue() {
        if (this.sendEmail.invalid) {
            this.sendEmail.markAllAsTouched();

            if (this.sendEmail.invalid) {
                this.mostrarAlerta = true;
                this.alertMessage = "complete el formulario";
            }
        }

        const email = this.sendEmail.value.email;
        this.forgotPasswordService.$email.next(email);
        this.forgotPasswordService.changeIsLoading(true);
    }
}
