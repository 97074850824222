import {Component, EventEmitter, inject, Input, OnInit, Output, signal} from '@angular/core';
import { Modal } from 'flowbite';
import {AuthService} from "../../../core/01-auth/services/auth.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-popups',
  standalone: true,
  imports: [],
  templateUrl: './popups.component.html',
  styleUrl: './popups.component.scss'
})
export class PopupsComponent implements OnInit{
  private router = inject(Router);
  @Input() message!: string;
  @Output() close = new EventEmitter<void>();

  // $modal = document.getElementById('popup-modal');
  // modal = new Modal(this.$modal);

  title1Message: string="";
  title2textMessage: string="";
  textMessage: string="";

  alertMessage: string="";

  ngOnInit(): void {

    // if (!this.authService.showModal()){
    //   this.modal.hide();
    // } else {
    //   this.modal.show()
    // }

    console.log(this.message);
    //@ts-ignore
    this.title1Message=this.message.title1;
    //@ts-ignore
    this.title2textMessage=this.message.title2;
    //@ts-ignore
    this.textMessage=this.message.text;
  }


  closeAlert() {
    this.close.emit();
  }

  redirectTo() {
    this.router.navigate(['/create']);
  }
}
