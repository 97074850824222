<div class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div id="popup-modal" tabindex="-1" class="relative p-4 w-full max-w-md max-h-full">
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <button type="button" (click)="closemodal()"
                    class="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    data-modal-hide="popup-modal">
                <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
                     viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                </svg>
                <span class="sr-only">Close modal</span>
            </button>
            <div class="p-4 md:p-8 text-center">
                <!-- Título 1 -->
                <h1 class="mb-5 text-3xl font-bold text-black dark:text-black">{{ _data.title }}</h1>
                <!-- Título 2 -->
                @if (_data.subTitle) {
                    <h1 class="mb-5 text-3xl font-bold text-black dark:text-black">{{ _data.subTitle }}</h1>
                }
                <!-- Texto -->
                @if (_data.message) {
<!--                    <h6 class="mb-5 text-xs font-normal text-gray-500 dark:text-gray-400"> {{ _data.message }}</h6>-->
                    <h6 class="mb-5 text-xs font-normal text-gray-500 dark:text-gray-400" [innerHTML]="_data.message"></h6>
                }
                <section class="buttons grid grid-cols gap-4">
                    <!--                agregar boton cancelar -->
                    @if (_data.showCancelButton) {
                        <button data-modal-hide="popup-modal" (click)="closemodal()" type="button"
                                class="text-white font-medium rounded-full lg:font-bold text-sm flex justify-center items-center px-5 py-2.5 text-center"
                                style="background-color: #24b3c7;">
                            CANCELAR
                        </button>
                    }
                    @if (_data.showAcceptButton) {
                        <button data-modal-hide="popup-modal" (click)="closemodal()" type="button"
                                class="text-white font-medium rounded-full lg:font-bold text-sm flex justify-center items-center px-5 py-2.5 text-center"
                                style="background-color: #24b3c7;">
                            ACEPTAR
                        </button>
                    }
                    @if (_data.showRedirectButton) {

                        <button data-modal-hide="popup-modal" (click)="redirectTo()" type="button"
                                class="text-white rounded-full font-bold text-sm flex justify-center items-center px-5 py-2.5 text-center"
                                style="background-color: #24b3c7;">
                            {{ _data.primaryButton || 'Aceptar'}}
                        </button>
                    }
                    <!--                agregar boton cancelar -->
                    @if (_data.showActionButton) {
                        <button data-modal-hide="popup-modal" (click)="actionButton()" type="button"
                                class="text-white font-medium rounded-full lg:font-bold text-sm flex justify-center items-center px-5 py-2.5 text-center"
                                style="background-color: #24b3c7;">
                            {{ _data.actionButton || 'Aceptar'}}
                        </button>
                    }
                </section>
            </div>
        </div>
    </div>
</div>
