import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {VALIDATION_MESSAGES} from "../../../../shared/consts/validations.const";
import {DashboardService} from "../../../../dashboard.service";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Router, RouterLink} from "@angular/router";

@Component({
    selector: 'app-login-form',
    standalone: true,
    imports: [
        ReactiveFormsModule,
        RouterLink
    ],
    templateUrl: './login-form.component.html',
    styleUrl: './login-form.component.scss'
})
export class LoginFormComponent implements OnInit {
    @Output("authInfo") authInfo: EventEmitter<any> = new EventEmitter()
    public validationMessages = VALIDATION_MESSAGES;
    isLoading:boolean=false;
    constructor(private dashboardService: DashboardService) {
    }

    public authForm: FormGroup<any> = this.dashboardService.formAlta.get('personalData') as FormGroup;

    ngOnInit(): void {
        this.validadForm()
    }

    validadForm() {
        this.authForm = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required, Validators.minLength(8)]),
        })
    }

    public getValidationPersonalFromControl(validation: any, elem: string): any {
        try {
            //@ts-ignore
            return this.authForm.get(elem).invalid && this.authForm.get(elem).hasError(validation.type) && this.authForm.get(elem).touched
        } catch (e) {
            return false;
        }
   }

    emitForm() {
        // loading paso-1: Encender el loading
        this.isLoading=true;
        this.dashboardService.toogleLoading(this.isLoading);

        if (this.authForm.invalid) {
            this.authForm.markAllAsTouched();
            this.isLoading = false;
            return;
        }
        this.authInfo.emit(this.authForm.getRawValue());

        // loading paso-2: Escuchar la respuesta para apagarlo
        this.dashboardService.$isLoading.subscribe((isLoading: boolean) => {
            this.isLoading = isLoading;
        } );
    }

}
