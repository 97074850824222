<div class="px-8 py-6 mt-8 max-w-3xl mx-auto ">
    <h1 class="text-4xl font-bold">1. Creá tu cuenta</h1>
    <h6 class="">Te pedimos algunos datos para comenzar</h6>
</div>

<form [formGroup]="personalDataForm" class="space-y-5">
    <div class="px-8 py-4 max-w-3xl mx-auto border border-transparent rounded-3xl shadow-lg">
        <h2 class="text-lg font-bold">Datos personales</h2>
        <hr class="text-gray-300">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div class="space-y-1">
                <label for="document_type" class="block text-sm font-medium text-gray-300">Tipo de documento *</label>
                <select name="document_type" formControlName="document_type" id="document_type" class="block w-full py-2.5 px-3 text-sm text-gray-900 bg-transparent border border-gray-300 focus:outline-none focus:border-blue-600 dark:text-white dark:border-gray-600 dark:focus:border-blue-500 rounded-full" required>
                    <option value="" disabled selected>Selecciona una opción</option>
                    <option value="Dni">Dni</option>
                    <option value="Libreta civica">Libreta civica</option>
                    <option value="Libreta enrolamiento">Libreta enrolamiento</option>
                    <option value="Pasaporte">Pasaporte</option>
                </select>
                @for (validation of validationMessages.document_type!; track $index) {
                    @if (getValidationPersonalFromControl(validation, 'documentType')) {
                        <div>
                            <span style="color:red;"> {{ validation.message }} </span>
                        </div>
                    }
                }
            </div>
            <div>
                <div class="space-y-1">
                    <label for="first_name" class="block text-sm font-medium text-gray-300">Nombre *</label>
                    <input type="text" name="name" formControlName="name" id="first_name"
                           class="block w-full py-2.5 px-3 text-sm text-gray-900 bg-transparent border border-gray-300 focus:outline-none focus:border-blue-600 dark:text-white dark:border-gray-600 dark:focus:border-blue-500 rounded-full"
                           placeholder="Nombre" required/>
                </div>
                @for (validation of validationMessages.name!; track $index) {
                    @if (getValidationPersonalFromControl(validation, 'name')) {
                        <div>
                            <span style="color:red;"> {{ validation.message }} </span>
                        </div>
                    }
                }
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
                <div class="space-y-1">
                    <label for="document_number" class="block text-sm font-medium text-gray-300">Número de documento *</label>
                    <input type="text" name="document_number" formControlName="document_number" id="document_number"
                           class="block w-full py-2.5 px-3 text-sm text-gray-900 bg-transparent border border-gray-300 focus:outline-none focus:border-blue-600 dark:text-white dark:border-gray-600 dark:focus:border-blue-500 rounded-full"
                           placeholder="Número de documento" required/>
                </div>
                @for (validation of validationMessages.document_number!; track $index) {
                    @if (getValidationPersonalFromControl(validation, 'documentNumber')) {
                        <div>
                            <span style="color:red;"> {{ validation.message }} </span>
                        </div>
                    }
                }
            </div>
            <div>
                <div class="space-y-1">
                    <label for="last_name" class="block text-sm font-medium text-gray-300">Apellido *</label>
                    <input type="text" name="last_name" formControlName="last_name" id="last_name"
                           class="block w-full py-2.5 px-3 text-sm text-gray-900 bg-transparent border border-gray-300 focus:outline-none focus:border-blue-600 dark:text-white dark:border-gray-600 dark:focus:border-blue-500 rounded-full"
                           placeholder="Apellido" required/>
                </div>
                @for (validation of validationMessages.lastname!; track $index) {
                    @if (getValidationPersonalFromControl(validation, 'lastname')) {
                        <div>
                            <span style="color:red;"> {{ validation.message }} </span>
                        </div>
                    }
                }
            </div>
        </div>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div class="space-y-1">
                <label for="gender" class="block text-sm font-medium text-gray-300">Género *</label>
                <select name="gender" formControlName="gender" id="gender" class="block w-full py-2.5 px-3 text-sm text-gray-900 bg-transparent border border-gray-300 focus:outline-none focus:border-blue-600 dark:text-white dark:border-gray-600 dark:focus:border-blue-500 rounded-full" required>
                    <option value="" disabled selected>Selecciona una opción</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Femenino">Femenino</option>
                    <option value="No informado">No informado</option>
                </select>
                @for (validation of validationMessages.gender!; track $index) {
                    @if (getValidationPersonalFromControl(validation, 'gender')) {
                        <div>
                            <span style="color:red;"> {{ validation.message }} </span>
                        </div>
                    }
                }
            </div>
            <div>
                <div class="space-y-1">
                    <label for="email" class="block text-sm font-medium text-gray-300">Email *</label>
                    <input type="text" name="email" formControlName="email" id="email"
                           class="block w-full py-2.5 px-3 text-sm text-gray-900 bg-transparent border border-gray-300 focus:outline-none focus:border-blue-600 dark:text-white dark:border-gray-600 dark:focus:border-blue-500 rounded-full"
                           placeholder="Email" required/>
                </div>
                @for (validation of validationMessages.email!; track $index) {
                    @if (getValidationPersonalFromControl(validation, 'email')) {
                        <div>
                            <span style="color:red;"> {{ validation.message }} </span>
                        </div>
                    }
                }
            </div>
        </div>
    </div>

    <div class="px-8 mt-8 max-w-3xl mx-auto">
        <h4 class=" text-sm font-medium text-gray-300">* Campos obligatorios</h4>
        <div class="flex items-baseline mt-4">
            <input class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" id="default-checkbox" type="checkbox" formControlName="acceptTerms">
            <label for="default-checkbox" class="flex-none ms-1 text-xs font-medium text-gray-900 dark:text-gray-300">Acepto los </label>
            <label class=" ms-1 text-sm font-medium text-gray-900 dark:text-gray-300 whitespace-normal" style="color: rgb(147, 114, 246)">Términos y condiciones y las políticas de privacidad</label>
        </div>
        @for (validation of validationMessages.acceptTerms!; track $index) {
            @if (getValidationPersonalFromControl(validation, 'acceptTerms')) {
                <div>
                    <span style="color:red;"> {{ validation.message }} </span>
                </div>
            }
        }
        <div class="py-4 flex justify-center md:justify-end">
            @if (!isLoading) {
                <button class="bg-purple-600 text-white w-60 h-14 rounded-full font-bold" (click)="onContinue()">
                    Continuar
                </button>
            } @else {
                <button type="button" class="bg-purple-600 text-white w-60 h-14 rounded-full font-bold">
                    <svg aria-hidden="true" role="status"
                         class="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101"
                         fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"/>
                    </svg>
                    Cargando...
                </button>
            }
        </div>
    </div>
</form>
