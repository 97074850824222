@if (!steps()[0].details) {
    <div *ngIf="animationState === 'closed'" id="closed">
        <div class="w-full bg-[#F4F5FA] h-[15vh] py-8 px-6 lg:px-4 xl:px-8 flex flex-col">
            <div class="map-title border-b-2 mb-12 flex justify-between">
                <h2 class="inline-flex items-center uppercase tracking-[4.5px] text-[#676767]">
                    <span class="material-symbols-outlined text-[#676767] mr-4">location_on</span>
                    {{ title() }}
                </h2>
                <button class="cursor-pointer" (click)="collapseElement()">
                    <span class="material-symbols-outlined">expand_more</span>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="animationState === 'open'" id="open" [@collapse]="animationState" (@collapse.done)="animationDone()">
        <div class="w-full bg-[#F4F5FA] h-auto lg:h-[130vh] py-8 px-6 lg:px-4 xl:px-8 flex flex-col">
            <div class="map-title border-b-2 mb-12 flex justify-between">
                <h2 class="inline-flex items-center uppercase tracking-[4.5px] text-[#676767]">
                    <span class="material-symbols-outlined text-[#676767] mr-4">location_on</span>
                    {{ title() }}
                </h2>
                <button class="cursor-pointer lg:hidden" (click)="collapseElement()">
                    <span class="material-symbols-outlined">expand_less</span>
                </button>
            </div>

            <div class="map-items pl-10 lg:pl-6 xl:pl-10">
                <ol class="relative text-gray-500 border-s border-dotted border-gray-200 dark:border-gray-700 dark:text-gray-400 pl-2">
                    @for (step of steps(); track step) {
                        <li class="mb-6 ms-6">
                            @if (step.status === StepperStatus.DONE) {
                                <span class="step-completed">
                            <svg class="w-3.5 h-3.5 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                 fill="none" viewBox="0 0 16 12">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M1 5.917 5.724 10.5 15 1.5"/>
                            </svg>
                        </span>
                            } @else if (step.status === StepperStatus.ACTIVE) {
                                <span class="step-active">
                            <span class="">
                                    {{ step.position }}
                            </span>
                        </span>
                            } @else {
                                <span class="step-inactive">
                            <svg class="w-7 h-7 text-gray-500 dark:text-gray-400" aria-hidden="true"
                                 xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 40 40">
                                <circle cx="20" cy="20" r="10"/>
                            </svg>
                        </span>
                            }
                            <h3 class="font-medium leading-8">{{ step.title }}</h3>
                            <!--                        <div class="flex items-center justify-between">-->
                            <!--                            <p class="text-sm">Completado </p>-->
                            <!--                            @if (step.status === StepperStatus.DONE) {-->
                            <!--                                <p class="inline-flex items-center uppercase tracking-[2.5px] text-[#676767] text-xs">-->
                            <!--                                    EDITAR-->
                            <!--                                    <span class="material-symbols-outlined">edit_square</span>-->
                            <!--                                </p>-->
                            <!--                            }-->
                            <!--                        </div>-->

                        </li>
                    }
                </ol>
            </div>
        </div>
    </div>
} @else {
    <div *ngIf="animationState === 'closed'" id="closed">
        <div class="w-full bg-[#F4F5FA] h-[15vh] py-8 px-6 lg:px-4 xl:px-8 flex flex-col">
            <div class="map-title border-b-2 mb-12 flex justify-between">
                <h2 class="inline-flex items-center uppercase tracking-[4.5px] text-[#676767]">
                    <span class="material-symbols-outlined text-[#676767] mr-4">location_on</span>
                    {{ title() }}
                </h2>
                <button class="cursor-pointer" (click)="collapseElement()">
                    <span class="material-symbols-outlined">expand_more</span>
                </button>
            </div>
        </div>
    </div>
    <div *ngIf="animationState === 'open'" id="open" [@collapse]="animationState" (@collapse.done)="animationDone()">
        <div class="w-full bg-[#F4F5FA] h-auto lg:h-[130vh] py-8 px-6 lg:px-4 xl:px-8 flex flex-col">
            <div class="map-title border-b-2 mb-12 flex justify-between">
                <h2 class="inline-flex items-center uppercase tracking-[4.5px] text-[#676767]">
                    <span class="material-symbols-outlined text-[#676767] mr-4">location_on</span>
                    {{ title() }}
                </h2>
                <button class="cursor-pointer lg:hidden" (click)="collapseElement()">
                    <span class="material-symbols-outlined">expand_less</span>
                </button>
            </div>

            <div class="map-items pl-10 lg:pl-6 xl:pl-10">
                <ol class="relative text-gray-500 border-s border-dotted border-gray-200 dark:border-gray-700 dark:text-gray-400 pl-2">

                        @for (step of steps()[0].details.slice(0, 3); track step) {
                        <li class="mb-6 ms-6">
                                <span class="step-completed">
                                    <svg class="w-3.5 h-3.5 text-white" aria-hidden="true"
                                         xmlns="http://www.w3.org/2000/svg"
                                         fill="none" viewBox="0 0 16 12">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="2"
                                                  d="M1 5.917 5.724 10.5 15 1.5"/>
                                    </svg>
                                </span>
                            <h3 class="font-medium leading-8">{{ step.title }}</h3>
                            <div class="flex items-center justify-between">
                                <p class="text-sm">{{ step.description }} </p>
                            </div>

                        </li>
                    }
                </ol>
                <ol class="relative text-gray-500 border-s border-dotted border-gray-200 dark:border-gray-700 dark:text-gray-400 pl-2">

                @for (step of steps()[0].details.slice(3); track step) {
                <li class="mb-6 ms-6">
                                                <span class="step-completed">
                                                    <svg class="w-3.5 h-3.5 text-white" aria-hidden="true"
                                                         xmlns="http://www.w3.org/2000/svg"
                                                         fill="none" viewBox="0 0 16 12">
                                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                                  stroke-width="2"
                                                                  d="M1 5.917 5.724 10.5 15 1.5"/>
                                                    </svg>
                                                </span>
                    <h3 class="font-medium leading-8">{{ step.title }}</h3>
                    <div class="flex items-center justify-between">
                        <p class="text-sm">{{ step.description }} </p>
                    </div>

                </li>
                }
                </ol>
            </div>
        </div>
    </div>
}
