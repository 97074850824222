import {Component, inject, OnDestroy} from '@angular/core';
import {StepperComponent} from "../../shared/components/stepper/stepper.component";
import {MapStepperInterface, StepperStatus} from "../../shared/components/stepper/models/mapStepper.interface";
import {FormCreateAccountComponent} from "./components/form-create-account/form-create-account.component";
import {FormValidateAccountComponent} from "./components/form-validate-account/form-validate-account.component";
import {BehaviorSubject} from "rxjs";
import {CreateAccountService} from "./create-account.service";
import {ValidatePinComponent} from "../../shared/components/validate-pin/validate-pin.component";
import {PersonalDataInterface, Question} from "./models/personalDataInterface";
import {DialogService} from "../../shared/components/custom-dialog/dialog.service";
import {
    StepperHeaderComponent
} from "../../shared/components/stepper/components/stepper-header/stepper-header.component";
import {LocalStorageService} from "../../core/storage/local-storage.service";
import {FormCompleteAccountComponent} from "./components/form-complete-account/form-complete-account.component";
import {Router} from "@angular/router";
import {DashboardService} from "../../dashboard.service";
import {FooterComponent} from "../../shared/components/footer/footer.component";
import {User} from "../../shared/models/user-data.model";
import {SharedService} from "../../shared/shared.service";

@Component({
    selector: 'app-create-account',
    standalone: true,
    imports: [
        StepperComponent,
        StepperHeaderComponent,
        FooterComponent
    ],
    templateUrl: './create-account.component.html',
    styleUrl: './create-account.component.scss'
})
export class CreateAccountComponent implements OnDestroy {

    router = inject(Router);
    private createAccountService = inject(CreateAccountService);
    private dialogService = inject(DialogService);
    private localStorageService = inject(LocalStorageService);
    private dashboardService = inject(DashboardService);
    private sharedService = inject(SharedService);

    mapSteps: MapStepperInterface[] = [];
    pin: string = "";
    positionActive: number = 1;
    dataToValidate: any[] = [];
    $mapStepChange: BehaviorSubject<MapStepperInterface[]> = new BehaviorSubject<MapStepperInterface[]>(this.mapSteps);
    id_transaction: any;

    private personalDalta: PersonalDataInterface | undefined;

    constructor() {
        this.loadMapStep();
        this.sharedService.setContext('create-account');
        this.createAccountService.$personalData.subscribe((personalData: PersonalDataInterface | null) =>
            this.saveUserDataAndSendPin(personalData));
        this.createAccountService.$pin.subscribe((pin: string) => this.validateAccount(pin));
        this.createAccountService.$dataToValidate.subscribe((data: Question[]) => this.sendDataToValidate(data));
        this.createAccountService.$completeAccount.subscribe((completeAccount: any | null) => this.sendCompleteAccount(completeAccount));
    }

    ngOnDestroy(): void {
        this.createAccountService.$personalData.unsubscribe();
        this.createAccountService.$pin.unsubscribe();
        this.createAccountService.$dataToValidate.unsubscribe();
        this.createAccountService.$completeAccount.unsubscribe();
        this.createAccountService.reset();
    }

    private loadMapStep() {
        const mapStepSaved = this.localStorageService.getItem("mapStep");
        if (mapStepSaved && typeof mapStepSaved === "string") {
            this.mapSteps = JSON.parse(mapStepSaved);
        } else {
            this.mapSteps = [
                {
                    title: "Creá tu cuenta",
                    description: "datos personales",
                    component: FormCreateAccountComponent,
                    status: StepperStatus.ACTIVE,
                    position: 1
                },
                {
                    title: "Enviar codigo",
                    description: "Step 2 description",
                    component: ValidatePinComponent,
                    status: StepperStatus.PENDING,
                    position: 2
                },
                {
                    title: "Validá tus datos",
                    description: "Step 3 description",
                    component: FormValidateAccountComponent,
                    status: StepperStatus.PENDING,
                    position: 3
                },
                {
                    title: "Completá tu cuenta",
                    description: "Step 4 description",
                    component: FormCompleteAccountComponent,
                    status: StepperStatus.PENDING,
                    position: 4
                }
            ];
        }
        this.$mapStepChange.next(this.mapSteps);
        this.createAccountService.$personalData.next(null);
        this.createAccountService.$pin.next("");
        this.createAccountService.$dataToValidate.next([]);
    }

    private saveUserDataAndSendPin(personalDalta: PersonalDataInterface | null) {
        if (!personalDalta) {
            return;
        }

        this.createAccountService.sendPin(personalDalta.email).subscribe({
            next: (res: any) => {
                this.dashboardService.toogleLoading(false);
                if (res) {
                    this.updateMapSteps(1, StepperStatus.DONE, 2, StepperStatus.ACTIVE);
                }
            },
            error: (error: any) => {
                this.dashboardService.toogleLoading(false);
                if (error.status === 409) {
                    this.dialogService.error409("El correo electrónico ya está registrado. Por favor, utiliza otro o intenta iniciar sesión");
                } else {
                    this.dialogService.error500();
                }
            }
        });
    }

    private sendPersonalDataRetry(personalDalta: any | null) {
        if (!personalDalta) {
            return;
        }
        /*this.personalDalta = personalDalta;
        const personalDataSnakeCase = {
            lastname: personalDalta.last_name,
            name: personalDalta.name,
            document_type: personalDalta.document_type,
            document_number: personalDalta.document_number.toString(),
            email: personalDalta.email,
            gender: personalDalta.gender,
            accept_terms: true
        }*/
        this.createAccountService.sendPinRetry(personalDalta.email).subscribe({
            next: (res: any) => {
                this.dashboardService.toogleLoading(false);
                if (res) {
                    this.updateMapSteps(1, StepperStatus.DONE, 2, StepperStatus.ACTIVE);
                }
            },
            error: (error: any) => {
                this.dashboardService.toogleLoading(false);
                if (error.message === 'ERROR_RESOLVED') {
                    this.dashboardService.toogleLoading(false);
                    return;
                }
                this.dialogService.customMessage("No pudimos crear tu cuenta", error.message);
            }
        });
    }

    // Todo: Pasara al servicio. Se repite en forgot-password
    private updateMapSteps(stepPositionDone: number, statusDone: StepperStatus, stepPositionActive?: number, statusActive?: StepperStatus) {
        // @ts-ignore
        this.mapSteps = this.mapSteps.map(step => {
            if (step.position === stepPositionDone) {
                return {...step, status: statusDone};
            } else if (stepPositionActive && step.position === stepPositionActive) {
                this.positionActive = step.position;
                this.localStorageService.setItem("mapStep", JSON.stringify(this.mapSteps));
                return {...step, status: statusActive};
            } else {
                return step;
            }
        });
        this.$mapStepChange.next(this.mapSteps);
    }

    private validateAccount(pin: string) {
        if (!pin) {
            return;
        }
        this.pin = pin;
        this.createAccountService.validateAccount(pin).subscribe({
            next: (res: any) => {
                if (res) {
                    this.updateMapSteps(2, StepperStatus.DONE, 3, StepperStatus.ACTIVE);
                }
            },
            error: (error: any) => {
                // todo lanzar dialogo de error: no pudimos validar el pin, vuelve a intentarlo, no pudimos validar tu identidad. redirect login
                if (error.message === 'ERROR_RESOLVED') {
                    this.dashboardService.toogleLoading(false);
                    return;
                }
                if (error.message === 'Codigo invalido') {
                    this.dashboardService.toogleLoading(false);
                    const ref = this.dialogService.showPinMessage();
                    ref.afterClosed().subscribe(result => {
                        if (result === 'RETRY') {
                            const user = this.localStorageService.getItem("user");

                            if (user) {
                                this.sendPersonalDataRetry(user);
                            }
                        }
                    });
                }

                if (error.message !== 'Codigo invalido') {
                    this.dialogService.customMessage("No pudimos validar tu cuenta", "Por favor, intente más tarde");
                }
                this.dashboardService.toogleLoading(false);
            }
        });
    }

    private sendDataToValidate(data: Question[]) {
        // if (!Object || !data) {
        const tieneDatos = Object.keys(data).length > 0;
        if (!tieneDatos) {
            return;
        }

        let response = localStorage.getItem("APP-veraz_questionnaire");

        if (response !== null) {
            let questionnaireObject = JSON.parse(response);
            // Luego puedes continuar trabajando con questionnaireObject
            this.id_transaction = questionnaireObject.application_id;
        }
        const arrayData = Object.entries(data);
        const questionnaire = [];
        for (let i = 0; i < arrayData.length; i++) {
            const question = arrayData[i];
            const questionEntry = {
                "id_answer": question[1],
                "id_question": i + 1
            };
            questionnaire.push(questionEntry);
        }

        const validate = [
            {
                "id_transaction": this.id_transaction,
                "questionnaire": questionnaire
            }
        ];

        this.dataToValidate = validate;
        this.createAccountService.validateQuestionnaire(validate).subscribe({
            next: (res: any) => {
                if (res) {
                    this.updateMapSteps(3, StepperStatus.DONE, 4, StepperStatus.ACTIVE);
                }
            },
            error: (error: any) => {
                // todo lanzar dialogo de error: primer oportunidad, mostrar de nuevo las preguntas.
                // todo lanzar dialogo de error: segunda oportunidad, respondiste mal el cuestionario, no pudimos validar tu identidad. redirect login
            }
        });
    }

    private sendCompleteAccount(data: any | null) {
            if (data === undefined || data === null) {
            return;
        }
        // @ts-ignore
        this.createAccountService.createAccount(data).subscribe({
            next: (res: any) => {
                if (res) {
                    this.reset();
                    const dialogRef = this.dialogService.customMessage("¡Tu cuenta fue creada con Exito!", "Ahora podrás ingresar a la misma para gestionar y hacer uso de tus seguros.");
                    dialogRef.afterClosed().subscribe(result => {
                        this.router.navigate(['/login']);
                    });
                }
            },
            error: (error: any) => {
                this.dashboardService.toogleLoading(false);
                if (error.message === 'Token expirado') {
                    const dialogRef = this.dialogService.customMessage("No pudimos completar tu cuenta", "Tiempo agotado");
                    dialogRef.afterClosed().subscribe(result => {
                        this.router.navigate(['/login']);
                    });
                } else {
                    this.dialogService.error500();
                }
                this.dashboardService.toogleLoading(false);
            }
        });
    }

    headerPositionChange($event: number) {
        this.positionActive = $event;
    }

    private reset() {
        this.createAccountService.$personalData.unsubscribe();
        this.createAccountService.$pin.unsubscribe();
        this.createAccountService.$dataToValidate.unsubscribe();
        this.createAccountService.$completeAccount.unsubscribe();
        this.createAccountService.reset();
        this.dashboardService.resetForms();
    }
}
