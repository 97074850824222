import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {CreateAccountService} from "../../create-account.service";
import {LocalStorageService} from "../../../../core/storage/local-storage.service";
import {Router} from "@angular/router";
import { DialogService } from '../../../../shared/components/custom-dialog/dialog.service';
import {DashboardService} from "../../../../dashboard.service";
import {PersonalDataInterface} from "../../models/personalDataInterface";

@Component({
    selector: 'app-form-validate-account',
    standalone: true,
    imports: [ReactiveFormsModule],
    templateUrl: './form-validate-account.component.html',
    styleUrl: './form-validate-account.component.scss'
})
export class FormValidateAccountComponent implements OnInit{
    private router = inject(Router);
    private dialogService = inject(DialogService);
    private dashboardService = inject(DashboardService);
    private localStorage = inject(LocalStorageService);
    createAccountService = inject((CreateAccountService))
    questions: any;
    personalData: PersonalDataInterface | null = null;
    isLoading: boolean = false;
    validateDataForm: FormGroup = this.dashboardService.formAlta.get('validateAccount') as FormGroup;

    constructor() {
        this.createAccountService.$personalData.subscribe((personalData: PersonalDataInterface | null) =>
            this.personalData = personalData);
    }

    ngOnInit(): void {
        let questionnaire = this.localStorage.getItem("veraz_questionnaire");

        if (!questionnaire) {
            const ref = this.dialogService.customMessage('Error', 'No se encontró el cuestionario de verificación');
            ref.afterClosed().subscribe(() => {
                this.router.navigate(['/']);
            });
        }

        // @ts-ignore
        this.questions = questionnaire.questions;
        this.questions.forEach((question: any, i: number) => {
            // Crear un FormControl para cada pregunta
            const controlName = 'question' + question.id;
            const control = new FormControl('', Validators.required);
            this.validateDataForm.addControl(controlName, control);
            this.loadFormData()
        });
    }

    loadFormData() {
        const formData = this.dashboardService.getFormValues('validateAccount');
        this.validateDataForm.patchValue(formData);
    }

    validarData() {
        this.isLoading = true;
        if (this.validateDataForm.invalid) {
            this.validateDataForm.markAllAsTouched();
            this.isLoading = true;
            return;
        }
        const dataToValidate = this.validateDataForm.getRawValue();
        this.dashboardService.setFormValues('validateAccount', dataToValidate);
        this.createAccountService.$dataToValidate.next(dataToValidate)
    }
}
