import {Component, EventEmitter, input, Input, Output} from '@angular/core';
import {Coverage, InsurancePolicy} from "../../models/homeInterface";
import {JsonPipe} from "@angular/common";

@Component({
  selector: 'app-card-coverage',
  standalone: true,
  imports: [
    JsonPipe
  ],
  templateUrl: './policy-card.component.html',
  styleUrl: './policy-card.component.scss'
})
export class PolicyCardComponent {

  certificate = input.required<InsurancePolicy>();
  @Output() policySelected = new EventEmitter<InsurancePolicy>();
  @Output() policyToDownload = new EventEmitter<InsurancePolicy>();
  icon: string = "coberturas";
  constructor() {
  }

  viewPolicy(insurancePolicy: InsurancePolicy) {
    this.policySelected.emit(insurancePolicy);
  }

    downloadPolicy(insurancePolicy: InsurancePolicy) {
    console.log("Id poliza a descargar: "+ insurancePolicy.policy_id);
    console.log("Nombre poliza a descargar: "+ insurancePolicy.product);
      this.policyToDownload.emit(insurancePolicy);
  }

  capitalizeFirstLetter(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }
}
