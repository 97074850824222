import {Component, ElementRef, inject, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgClass} from "@angular/common";
import {ForgotPasswordService} from "../../../features/04-forgot-password/forgot-password.service";
import {CreateAccountService} from "../../../features/03-create-account/create-account.service";
import {VALIDATION_MESSAGES} from "../../consts/validations.const";
import {DashboardService} from "../../../dashboard.service";
import {DialogService} from "../custom-dialog/dialog.service";
import {SharedService} from "../../shared.service";

@Component({
    selector: 'app-validate-pin',
    standalone: true,
    imports: [ReactiveFormsModule, NgClass],
    templateUrl: './validate-pin.component.html',
    styleUrl: './validate-pin.component.scss'
})
export class ValidatePinComponent implements OnInit {
    public validationMessages = VALIDATION_MESSAGES;
    private dashboardService = inject(DashboardService);
    private dialogService = inject(DialogService);
    private sharedService = inject(SharedService);

    @ViewChild('continueButton') continueButton!: ElementRef;
    @ViewChildren('pinInput') pinInputs!: QueryList<ElementRef>;
    pinForm: FormGroup = this.dashboardService.formAlta.get('pinForm') as FormGroup;
    botonHabilitado: boolean = false;
    isLoading: boolean = false;
    forgotPasswordService = inject(ForgotPasswordService);
    createAccountService = inject(CreateAccountService);

    ngOnInit(): void {
        this.loadFormData();
    }

    loadFormData() {
        const formData = this.dashboardService.getFormValues('pinForm');
        this.pinForm.patchValue(formData);
    }

    verificarCamposCompletos(): void {
        // Verifica si todos los campos están completos
        this.botonHabilitado = this.pinForm.valid;
    }

    onPaste(event: ClipboardEvent) {
        event.preventDefault();
        const pasteData = event.clipboardData?.getData('text') || '';
        const sanitizedData = pasteData.replace(/\D/g, '').slice(0, 8);  // Elimina caracteres no numéricos y limita a 8 dígitos

        sanitizedData.split('').forEach((char, index) => {
            const controlName = `pin${index + 1}`;
            this.pinForm.get(controlName)?.setValue(char);
        });

        if (sanitizedData.length === 8) {
            this.continueButton.nativeElement.focus();
        } else {
            const nextInput = this.pinInputs.toArray()[sanitizedData.length];
            if (nextInput) {
                nextInput.nativeElement.focus();
            }
        }

    }

    onInput(event: Event, nextInput: ElementRef | null, prevInput: ElementRef | null) {
        const input = event.target as HTMLInputElement;
        const value = input.value;

        // Validar que solo se ingrese un número
        if (!/^\d$/.test(value)) {
            input.value = '';
            return;
        }

        if (value.length === 1 && nextInput) {
            nextInput.nativeElement.focus();
        }
    }

    onKeydown(event: KeyboardEvent, prevInput: ElementRef | null) {
        const input = event.target as HTMLInputElement;
        if (event.key === 'Backspace' && input.value === '' && prevInput) {
            prevInput.nativeElement.focus();
        }
    }

    continuar() {
        this.isLoading = true;
        this.dashboardService.toogleLoading(this.isLoading);
        if (this.pinForm.invalid) {
            this.pinForm.markAllAsTouched();
            this.isLoading = false;
            this.dialogService.customMessage("El formulario es inválido", "Por favor, complete todos los campos.");
            return;
        }

        const pinStr = Object.values(this.pinForm.getRawValue()).join('');
        this.dashboardService.setFormValues('pinForm', pinStr);

        this.emitPin(pinStr);

        this.dashboardService.$isLoading.subscribe((isLoading: boolean) => {
            this.isLoading = isLoading;
        });
    }

    emitPin(pinStr: string) {
        const context = this.sharedService.getContext();
        if (context === 'create-account') {
            this.createAccountService.$pin.next(pinStr);
        } else if (context === 'forgot-password') {
            this.forgotPasswordService.$pin.next(pinStr);
        }
    }
}
