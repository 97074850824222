import {inject, Injectable} from '@angular/core';
import {Utils} from "../util/utils";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpEvent, HttpHeaders} from "@angular/common/http";
import {catchError, map, Observable, ObservableInput, retry, tap, throwError, timeout, TimeoutError} from "rxjs";
import {DialogService} from "../../shared/components/custom-dialog/dialog.service";


export interface HttpClientOptions {
    headers?: { [key: string]: string | number | null } | null;
    params?: { [key: string]: string | number } | null;
    body?: { [key: string]: any } | null;
    config?: {
        bodyType?: 'json' | 'formData', // Default json
        retry?: number; // Default 0
    };
    responseType?: 'json' | 'text'; // Default 'json'
}

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    private customDialogService = inject(DialogService);
    defaultOptions: HttpClientOptions;

    constructor(private httpClient: HttpClient) {
        this.defaultOptions = {
            headers: {
                'content-Type': 'application/json; charset=utf-8',
            },
            params: {},
            body: {},
            config: {
                bodyType: 'json',
                retry: 0
            },
            responseType: 'json'
        };
    }

    public get<T>(resource: string, options?: HttpClientOptions, handleErrors?: boolean): Observable<T> {
        return this.xhr('get', resource, options, handleErrors);
    }

    public post<T>(resource: string, options?: HttpClientOptions, handleErrors?: boolean): Observable<T> {
        return this.xhr('post', resource, options, handleErrors);
    }

    public put<T>(resource: string, options?: HttpClientOptions, handleErrors?: boolean): Observable<T> {
        return this.xhr('put', resource, options, handleErrors);
    }

    public delete<T>(resource: string, options?: HttpClientOptions, handleErrors?: boolean): Observable<T> {
        return this.xhr('delete', resource, options, handleErrors);
    }

    public patch<T>(resource: string, options?: HttpClientOptions, handleErrors?: boolean): Observable<T> {
        return this.xhr('patch', resource, options, handleErrors);
    }

    private xhr<T>(method: string, resource: string, newOptions?: HttpClientOptions, handleErrors?: boolean): Observable<T> {
        const options = this.prepareHttpClientOptions(this.defaultOptions, newOptions);
        // Si se envia "http" significa que es absoluta, de otra manera concatena la api con el resource
        const endpoint = resource.substring(0, 4) === 'http' ? resource : `${environment.URL_BASE}${resource}`;
        return this.httpClient.request<T>(method, endpoint, options.optionsMerged)
            .pipe(
                timeout(environment.HTTP_TIME_OUT),
                map((response: HttpEvent<T>) => {
                    if ('path' in response && 'timestamp' in response && 'body' in response) {
                        return response.body;
                    }
                    return response;
                } ),
                // @ts-ignore
                catchError((err: any, caught: Observable<any>): ObservableInput<any> => {
                    handleErrors = handleErrors === undefined ? true : handleErrors;
                    if (handleErrors) {
                        if (err instanceof TimeoutError) {
                            this.customDialogService.errorTimeout();
                        }
                        if (err.status === 400) {
                            if (err.error && 'path' in err.error && 'timestamp' in err.error && 'body' in err.error) {
                                throw new Error(err.error.error);
                            }
                            throw new Error(err.error.message);
                        }
                        if (err.status === 401) {
                            this.customDialogService.error401();
                        }
                        if (err.status === 404) {
                            this.customDialogService.error404();
                        }
                        if (err.status === 409) {
                            this.customDialogService.error409();
                        }
                        if (err.status === 408 || err.status === 409 || err.status === 500) {
                            this.customDialogService.error500();
                        }
                        if (err.status === 0) {
                            this.customDialogService.error500('Servicios no disponibles');
                        }
                    }

                    return throwError(() => err);
                })
            );
    }

    /**
     * Para borrar un header, enviar con value en `null`
     */
    private setHeaders(optionsMerged: any) {
        let headers = new HttpHeaders();
        Object.keys(optionsMerged.headers).forEach((key) => {
            if (optionsMerged.headers[key] !== null) {
                headers = headers.append(key, optionsMerged.headers[key]);
            }
        });

        return headers;
    }

    private prepareHttpClientOptions(previousOptions: HttpClientOptions, newOptions?: HttpClientOptions): any {
        const optionsMerged: any = {};

        const options: HttpClientOptions = Utils.mergeDeep(previousOptions, newOptions);

        optionsMerged.headers = this.setHeaders(options);
        optionsMerged.params = options.params;
        if (options.config!.bodyType === 'json') {
            optionsMerged.body = Utils.isEmpty(options.body) ? undefined : options.body;
        } else {
            // just for formData, you need to send the reference, merged does not work
            if (newOptions!.body) {
                optionsMerged.body = newOptions!.body;
            }
        }
        optionsMerged.responseType = options.responseType;

        return {
            optionsMerged,
            config: options.config
        };
    }
}
